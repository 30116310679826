import { Link } from "react-router-dom";

import Header from "../components/Header";
import Social from "../components/Social";
import { MoreProducts } from "../components/Products";


const NotFoundPage: React.FC<{}> = () => {
    return (
        <div className="page-wrapper">
            <Header />
            <div className="container light-bg not-found">
                <h4 className="title">Страница не найдена</h4>
                <Link to="/" className="link-back">Вернуться на главную</Link>
            </div>
            <MoreProducts />
            <Social light />
        </div>
    )
}

export default NotFoundPage;