import { useState } from "react"
import { useTranslation } from "react-i18next";

const KEY = "show-back-to-old";

const getShow = () => {
    const show = localStorage.getItem(KEY);
    return !show;
}

const BackToOld: React.FC = () => {
    const { t } = useTranslation();
    const [show, setShow] = useState(() => getShow());
    
    const hide = () => {
        localStorage.setItem(KEY, "hidden");
        setShow(false);
    }

    return (
        <div className={`back-to-old ${show ? "show" : ""}`}>
            <div className="container">
                <a href="https://old.viksi.me/" className="title">{t("back_to_old")}</a>
                <button className="close" onClick={hide}>×</button>
            </div>
        </div>
    )
}

export default BackToOld;