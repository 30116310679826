import { Link } from "react-router-dom";

import Breadcrumb from "../components/Breadcrumb";
import Header from "../components/Header";
import Social from "../components/Social";
import { Feedback } from "../components/Feedback";
import { DashboardProduct } from "../components/Products";

import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { useTranslation } from "react-i18next";
import PasswordChange from "../components/PasswordChange";

const NoProductsPlaceholder: React.FC = () => {
    const { t } = useTranslation();

    return (
        <div className="no-products-placeholder">
            <img className="icon" src="/icon/face-sad-cry.svg" alt="" />
            <span className="label">{t("products_empty_label")}</span>
        </div>
    )
}

const ProfilePage: React.FC<{}> = () => {
    const { t } = useTranslation()
    const { user } = useContext(AuthContext);

    const path = [
        {
            title: t("nav_home"),
            link: "/"
        },
        {
            title: t("nav_profile")
        }
    ]

    return (
        <div className="page-wrapper dashboard">
            <Header />
            <div className="container">
                <Breadcrumb path={path} />
                <div className="title-wrapper">
                    <h2 className="title">{t("nav_profile")}</h2>
                </div>
                <div className="profile-wrapper">
                    <div className="profile-stats">
                        <div className="profile-stat">
                            <div className="label">Email:</div>
                            <div className="stat">{user?.email}</div>
                        </div>
                        <div className="profile-stat">
                            <div className="label">{t("form_label_phone")}:</div>
                            <div className="stat">{user?.phone || t("not_assigned")}</div>
                        </div>
                        <div className="profile-stat">
                            <div className="label">{t("courses_purchased")}:</div>
                            <div className="stat">{user?.products.length}</div>
                        </div>
                    </div>
                    <div className="password-change-wrapper">
                        <PasswordChange />
                    </div>
                </div>
            </div>
            <Feedback />
            <Social light />
        </div>
    )
}

export default ProfilePage;