import { YMInitializer } from 'react-yandex-metrika';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import Router from "./pages";
import "./localization";
import "./styles/index.sass";

import { AuthContextProvider } from './context/AuthContext';
import { ProductContextProvider } from './context/ProductContext';
import { RefController } from './components/RefController';
import { LangController } from './components/LangController';

export const CDN_BASE = "https://viksi.fra1.cdn.digitaloceanspaces.com";

const METRICA_ID = Number(process.env.REACT_APP_METRIKA_ID);

console.log('METRICA_ID', METRICA_ID);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(

    <AuthContextProvider>
      <ProductContextProvider>
        <BrowserRouter>
          <YMInitializer accounts={[METRICA_ID]} />
          <RefController />
          <LangController />
          <Router />
        </BrowserRouter>
      </ProductContextProvider>
    </AuthContextProvider>
    
);
