import axios from "axios";
import {useState} from "react";
import { useTranslation } from "react-i18next";
import FormInput from "./FormInput";

const PasswordChange: React.FC<{}> = () => {
    const { t } = useTranslation();

    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const [current, setCurrent] = useState("")
    const [password, setPassword] = useState("");
    const [passwordRepeat, setPasswordRepeat] = useState("");

    function changePassword(){
        setError(null);
        setLoading(true);
        axios.post("/api/user/change-password", {
            current_password: current,
            new_password: password
        }).then(({data}: any) => {
            setSuccess(true);
        }).catch((error: any) => {
            setError(t("error_password_incorrect"))
        }).finally(() => {
            setLoading(false);
        })
    }

    const submitButtonDisabled = 
        loading ||
        success ||
        password.length < 8 ||
        passwordRepeat.length < 8 ||
        changePassword.length > 8 ||
        password !== passwordRepeat

    const inputDisabled =
        loading ||
        success

    return (
        <div className="form-wrap password-change">
            {error && <div className="form-error">{error}</div>}
            {success && <div className="form-success">{t("change_password_success")}</div>}
            <FormInput
                label={t("form_label_password_current")}
                value={current}
                disabled={inputDisabled}
                setValue={setCurrent}
                type="text"
            />
            <FormInput
                label={t("form_label_password_new")}
                value={password}
                disabled={inputDisabled}
                setValue={setPassword}
                type="text"
            />
            <FormInput
                label={t("form_label_password_repeat")}
                value={passwordRepeat}
                disabled={inputDisabled}
                setValue={setPasswordRepeat}
                type="text"
            />
            <button
                disabled={submitButtonDisabled}
                className={`form-submit ${loading ? "loading" : ""}`}
                onClick={changePassword}
                children={t("change_password")}
            />
        </div>
    )
}

export default PasswordChange;