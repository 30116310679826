import Reviews from "../components/Reviews";
import Header from "../components/Header";
import Social from "../components/Social";
import { MoreProducts } from "../components/Products";
import Breadcrumb from "../components/Breadcrumb";
import { useCallback, useContext, useMemo, useState } from "react";
import { ProductDispatchContext, ProductContext } from "../context/ProductContext";
import { IProduct } from "../api/type";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { Link } from "react-router-dom";
import { AuthContext, AuthDispatchContext } from "../context/AuthContext";
import { CDN_BASE } from "..";
import FormInput from "../components/FormInput";


function getErrorToken(code: string){
    switch (code) {
        case "EMAIL_ALREADY_USED":
            return "error_email_exists";
        case "PROMOCODE_USED":
            return "error_promocode_used";
        case "PROMOCODE_NOT_FOUND":
            return "error_promocode_not_found";
        case "PROMOCODE_INACTIVE":
            return "error_promocode_inacive";
        case "PROMOCODE_TYPE_MISMATCH":
            return "error_promocode_unacceptable";
        case "DISCOUNT_TOO_BIG":
            return "error_promocode_unacceptable";
        default:
            return "error_order";
    }
}

const CartEmpty: React.FC = () => {
    const { t } = useTranslation();

    return (
        <div className="cart-empty">
            <p className="empty-label">{t("cart-empty")}</p>
            <Link
                to="/catalog"
                className="btn-link"
                children={t("goto_catalog_cta")}
            />
        </div>
    )
} 

function isDeliveryNeeded(cart: string[], products: IProduct[]){
    return cart.some(item => {
        const product = products.find(product => product._id === item);
        return product && product.delivery;
    })
}

const CartProduct: React.FC<{product: IProduct}> = ({ product }) => {
    const { i18n } = useTranslation();
    const dispatch = useContext(ProductDispatchContext);

    const deleteFromCart = useCallback(() => dispatch({
        type: "CART_REMOVE",
        item: product._id
    }), [product, dispatch])

    return (
        <div className="cart-product">
            <div className="image-wrapper">
                <img
                    className="image"
                    src={`${CDN_BASE + "/" + product[i18n.language === "ru" ? "cover" : "cover_en"]}`}
                    alt={product[i18n.language === "ru" ? "title_ru" : "title_en"]}
                />
            </div>
            <div className="info">
                <div className="title">{product[i18n.language === "ru" ? "title_ru" : "title_en"]}</div>
                <div className="price">{product.price} ₽</div>
            </div>
            <div
                onClick={deleteFromCart}
                className="cart-delete"
                children="✕"
            />
        </div>
    )
}

export interface IDelivery {
    name: string
    country: string
    index: string
    address: string
    phone: string
}

const DeliveryDetails: React.FC<{
    value: IDelivery,
    setValue: (d: IDelivery) => void
}> = ({value, setValue}) => {
    const { t } = useTranslation();

    const setProp = (prop: keyof IDelivery) => (v: string) => {
        setValue({...value, [prop]: v});
    } 

    return (
        <>
            <FormInput
                label={t("form_label_name")}
                value={value.name}
                setValue={setProp("name")}
            />
            <FormInput
                label={t("form_label_country")}
                value={value.country}
                setValue={setProp("country")}
            />
            <FormInput
                label={t("form_label_index")}
                value={value.index}
                setValue={setProp("index")}
            />
            <FormInput
                label={t("form_label_address")}
                value={value.address}
                setValue={setProp("address")}
            />
            <FormInput
                label={t("form_label_phone")}
                value={value.phone}
                setValue={setProp("phone")}
            />
        </>
    )
}

const BASE_DELIVERY: IDelivery = {
    name: "",
    country: "",
    index: "",
    address: "",
    phone: ""
}

const Order: React.FC<{
    onRedirect: () => void,
    totalPrice: number
}> = ({onRedirect, totalPrice}) => {
    const { t } = useTranslation();
    const { cart, products } = useContext(ProductContext);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const [promocode, setPromocode] = useState("");

    const [delivery, setDelivery] = useState<IDelivery>(BASE_DELIVERY)

    const createOrder = useCallback(() => {
        if(isDeliveryNeeded(cart, products) && (
            !delivery.address.length ||
            !delivery.country.length ||
            !delivery.index.length   ||
            !delivery.name.length    ||
            !delivery.phone.length
        )) return setError(t("provide_address"));
        setLoading(true);
        setError(null);
        axios.post("/api/order", Object.assign({
            products: cart,
            name: delivery.name,
            country: delivery.country,
            index: delivery.index,
            phone: delivery.phone,
            address: delivery.address
        }, promocode ? {promocode} : {})).then(({data}) => {
            onRedirect();
            window.location.assign(data.link);
        }).catch(error => {
            setError(t(getErrorToken(error.response.data.message)))
        }).finally(() => {
            setLoading(false);
        })
    }, [cart, promocode, products, delivery, onRedirect, t])

    return (
        <div className="order-signup auth-form cart-form">
            { error ? <div className="auth-error">{error}</div> : null }
            <FormInput
                label={t("form_label_promocode")}
                value={promocode}
                setValue={setPromocode}
            />
            {
                isDeliveryNeeded(cart, products)
                    ?
                    <DeliveryDetails
                        value={delivery}
                        setValue={setDelivery}
                    />
                    :
                    null
            }
            <div className="total-price">
                <span className="label">{t("cart_total")}:</span>
                <span className="price">{totalPrice} ₽</span>
            </div>
            <button
                disabled={loading}
                onClick={createOrder} 
                className={`btn-link ${loading ? "loading" : ""}`}
                children={t("goto_payment_button")}
            />
        </div>
    )
}

const OrderSignup: React.FC<{
    onRedirect: () => void,
    totalPrice: number
}> = ({onRedirect, totalPrice}) => {
    const { t } = useTranslation();
    const dispatch = useContext(AuthDispatchContext);
    const { cart, products } = useContext(ProductContext);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [promocode, setPromocode] = useState("");

    const [delivery, setDelivery] = useState<IDelivery>(BASE_DELIVERY)

    const createOrder = useCallback(() => {
        if(isDeliveryNeeded(cart, products) && (
            !delivery.address.length ||
            !delivery.country.length ||
            !delivery.index.length   ||
            !delivery.name.length    ||
            !delivery.phone.length
        )) return setError(t("provide_address"));
        setError(null);
        setLoading(true);
        axios.post("/api/order/guest", Object.assign({
            products: cart,
            name: delivery.name,
            country: delivery.country,
            index: delivery.index,
            address: delivery.address,
            phone: delivery.phone,
            email: email,
        }, promocode ? {promocode} : {})).then(({data}) => {
            onRedirect();
            dispatch({type: "login", user: data.user});
            window.location.assign(data.link);
        }).catch(error => {
            setError(t(getErrorToken(error.response.data.message)))
        }).finally(() => {
            setLoading(false);
        })
    }, [cart, email, promocode, delivery, products, dispatch, t, onRedirect]);
    
    return (
        <div className="order-signup form-wrap cart-form">
            {
                error ? <div className="form-error">{error}</div> : null
            }
            <FormInput
                label={t("form_label_promocode")}
                value={promocode}
                setValue={setPromocode}
            />
            <FormInput
                label={t("form_label_email")}
                value={email}
                setValue={setEmail}
            />
            {
                isDeliveryNeeded(cart, products)
                    ?
                    <DeliveryDetails
                        value={delivery}
                        setValue={setDelivery}
                    />
                    :
                    null
            }
            <div className="total-price">
                <span className="label">{t("cart_total")}:</span>
                <span className="price">{totalPrice} ₽</span>
            </div>
            <button
                disabled={loading}
                onClick={createOrder} 
                className={`btn-link ${loading ? "loading" : ""}`}
                children={t("goto_payment_button")}
            />
        </div>
    )
}

const CartPage: React.FC<{}> = () => {
    const { t } = useTranslation();
    const { cart, products } = useContext(ProductContext);
    const { isLoggedIn } = useContext(AuthContext);
    const [overlay, setOverlay] = useState(false);

    const cart_products = useMemo(() => cart.map(item => products.find(product => product._id === item)), [cart, products]) as IProduct[];
    const total_price = cart_products.reduce((prev, curr) => prev + curr.price, 0);

    const path = [
        {
            title: t("nav_home"),
            link: "/"
        },
        {
            title: t("nav_catalog"),
            link: "/catalog"
        },
        {
            title: t("nav_cart")
        }
    ]

    return (
        <div className="page-wrapper">
            <Header />
            <div className={`order-creating-overlay ${overlay ? "active" : ""}`}>
                {t("order_loading")}
            </div>
            <div className="container">
                <Breadcrumb path={path} />
                <div className="title-wrapper">
                    <h2 className="title">{t("nav_cart")}</h2>
                </div>
                {
                    cart_products.length ?
                        <div className="cart-wrapper">
                            <div className="order">
                                <div className="cart-items">
                                    {
                                        cart_products.map(product => <CartProduct key={product._id} product={product} /> )
                                    }
                                </div>
                            </div>
                            <div className="details-wrapper">
                                {
                                    isLoggedIn ?
                                        <Order
                                            totalPrice={total_price}
                                            onRedirect={() => setOverlay(true)}
                                        />
                                        :
                                        <OrderSignup
                                            totalPrice={total_price}
                                            onRedirect={() => setOverlay(true)}
                                        />
                                        
                                }
                            </div>
                        </div>
                        :
                        <CartEmpty />
                }
            </div>
            <MoreProducts />
            <Reviews />
            <Social light />
        </div>
    )
}

export default CartPage;