import axios from "axios";
import {useState, useEffect} from "react";
import { useParams } from "react-router-dom"
import { useNavigate } from "react-router";

import Header from "../components/Header";
import Social from "../components/Social";
import Preloader from "../components/Preloader";
import { LotteryData } from "../api/type";


const LotteryForm: React.FC<{lottery: LotteryData}> = ({lottery}) => {
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [link, setLink] = useState<null | string>(null)

    const validateEmail = (email: string) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email)
      };

    const participateCb = (id: string) => {
        setLoading(true)
        axios({
            url: `/api/lottery/${id}/participate`,
            method: "post",
            data: {
                name: name,
                email: email,
            }
        }).then(({data}) => {
            setLink(data);
        }).catch((err) => {
            alert("Произошла ошибка!")
            console.error(err)
        }).finally(() => {
            setLoading(false);
        })
    }

    return (
        <div className="file-form-wrapper">
            <h2 className="page-title">{lottery.page_title}</h2>
            <div className="file-form">
                <img src="/icon/basket-shopping.svg" className="icon" alt="" />
                <input
                    className="input"
                    disabled={loading || !!link}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email"
                />
                <input
                    className="input"
                    disabled={loading || !!link}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Ваше имя"
                />
                {
                    link ?
                        (
                            <a href={link} target="_blank" rel="noreferrer" className={`btn-link`}>Перейти к оплате</a>
                        )
                        :
                        (
                            <button
                                disabled={loading || !validateEmail(email) || !name.length}
                                onClick={() => participateCb(lottery._id)}
                                className={`btn-link ${loading ? "loading" : ""}`}
                                children={`Купить за ${lottery.price.toLocaleString("ru", {style: "currency", currency: "rub"})}`}
                            />
                        )
                }
            </div>
        </div>
    )
}

const FilePage: React.FC<{}> = () => {
    const { link } = useParams();
    const navigate = useNavigate();
    const [file, setFile] = useState<LotteryData | null>(null);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        axios({
            url: `/api/lottery/link/${link}`,
            method: 'get'
        }).then(({data}) => {
            console.log(data);
            setFile(data);
        }).catch(err => {
            navigate("/");
        }).finally(() => {
            setLoaded(true);
        })
    }, [link, navigate, setFile]);

    return (
        <div className="page-wrapper">
            <Header />
            <div className="light_bg full-page-block">
                {
                    loaded && file
                        ? <LotteryForm lottery={file} />
                        : <Preloader />
                }
                
            </div>
            <Social />
        </div>
    )
}

export default FilePage;