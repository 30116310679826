import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ym from "react-yandex-metrika";


const ProjectTiles: React.FC<{}> = () => {
    const { t } = useTranslation();

    return (
        <section className="project-tiles">
            <div className="container">
                <div className="tile tile-wide-left tile-4">
                    <h4 className="title">{t("tile_1")}</h4>
                    <Link to="/catalog" className="btn-secondary accent"  onClick={() => ym('hit', `project-titles`)}>{t("follow_link")}</Link>
                </div>
                <div className="tile tile-2">
                    <h4 className="title">{t("tile_2")}</h4>
                    <a className="btn-secondary accent" href="/file/guide">{t("follow_link")}</a>
                </div>
                <div className="tile tile-3">
                    <h4 className="title">{t("tile_3")}</h4>
                    <a className="btn-secondary accent" href="https://t.me/+Pp9FvcNHFNYxZjgy">{t("follow_link")}</a>
                </div>
                <div className="tile tile-wide-left tile-1">
                    <h4 className="title">{t("tile_4")}</h4>
                    <a className="btn-secondary accent" href="https://viksi666.ru">{t("follow_link")}</a>
                </div>
                <div className="tile tile-wide-right tile-5">
                    <h4 className="title">{t("tile_5")}</h4>
                    <a className="btn-secondary accent" href="https://youtube.com/viksi666">{t("follow_link")}</a>
                </div>
            </div>
        </section>
    )
}

export default ProjectTiles;