import axios from "axios";
import {useState, useContext} from "react";
import { Link } from "react-router-dom"
import { AuthDispatchContext } from "../context/AuthContext";
import { useNavigate } from "react-router";

import Header from "../components/Header";
import Social from "../components/Social";
import { useTranslation } from "react-i18next";
import FormInput from "../components/FormInput";

const LoginForm: React.FC<{}> = () => {
    const { t } = useTranslation();
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const dispatch = useContext(AuthDispatchContext);
    const navigate = useNavigate();

    const [codeSent, setCodeSent] = useState(false);
    const [phone, setPhone] = useState("");
    const [code, setCode] = useState("");

    function requestPhoneVerification(){
        setError(null);
        setLoading(true);
        axios.post("/api/user/request-phone-verification", {
            phone
        }).then(({data}: any) => {
            console.log(data);
            setCodeSent(true);
        }).catch((error: any) => {
            console.log(error)
            setError(t("sms_unable"))
        }).finally(() => {
            setLoading(false);
        })
    }

    function submitPhoneVerification(){
        setError(null);
        setLoading(true);
        axios.post("/api/user/submit-phone-verification", {
            code
        }).then(({data}: any) => {
            dispatch({
                type: "update",
                user: {
                    phone: data.phone,
                    phone_verified: true
                }
            })
        }).catch((error: any) => {
            console.log(error);
            setError(t("code_invalid"))
        }).finally(() => {
            setLoading(false);
        })
    }
    
    const resetPhone = () => {
        setCodeSent(false);
        setPhone("");
    }

    return (
        <div className="form-wrap form">
            <div className="form-title">{t("verify_phone_form_title")}</div>
            <div className="tip">{t("verify_phone_tip")}</div>
            {
                error
                    ?
                    <div className="form-error">{error}</div>
                    :
                    null
            }
            <FormInput
                label={t("form_label_phone")}
                value={phone}
                setValue={setPhone}
                disabled={codeSent || loading}
            />
            <FormInput
                label={t("form_label_code")}
                value={code}
                setValue={setCode}
                disabled={!codeSent || loading}
            />
            {
                codeSent && <span className="change-phone" onClick={resetPhone}>Изменить номер телефона</span>
            }
            <button
                disabled={
                    loading || (codeSent && code.length !== 6) || (!codeSent && phone.length < 8)
                }
                className="form-submit"
                children={t(
                    codeSent
                        ? "submit_phone_verification"
                        : "request_phone_verification"
                )}
                onClick={
                    codeSent
                        ? submitPhoneVerification
                        : requestPhoneVerification

                }
            />
        </div>
    )
}

const LinkPhonePage: React.FC<{}> = () => {
    return (
        <div className="page-wrapper">
            <Header />
            <div className="light-bg full-page-block">
                <LoginForm />
            </div>
            <Social />
        </div>
    )
}

export default LinkPhonePage;