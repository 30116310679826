import Header from "../../components/Header";
import Breadcrumb from "../../components/Breadcrumb";
import { MoreProducts } from "../../components/Products";
import Social from "../../components/Social";

const path = [
    {
        title: "Главная",
        link: "/"
    },
    {
        title: "Политики и оферта",
        link: "/policy"
    },
    {
        title: "Информация о доставке"
    }
]

const Doc4Page: React.FC<{}> = () => {
    return (
        <div className="page-wrapper">
            <Header />
            <div className="container light-bg">
                <Breadcrumb path={path} />
                <div className="title-wrapper">
                    <h2 className="title">Информация о доставке</h2>
                </div>
                <div className="doc">
                    <h1>Информация о доставке</h1>
                    <h4>Доставку осуществляет компания СДЭК</h4>
                    <p>Отправки возможно осуществлять внутри одного города либо по России.</p>
                    <p>Возможные режимы доставки отправлений:</p>
                    <p>- прием и выдача товара со склада СДЭК (режим склад-склад);</p>
                    <p>- доставка товара со склада СДЭК до получателя (режим склад- дверь);</p>
                    <p>- доставка товара от отправителя и выдача со склада СДЭК (режим дверь-склад)</p>
                    <p>- доставка товара от отправителя до получателя (режим дверь- дверь).</p>
                    <p>Тарифы на основные услуги (вес посылки до 3кг.)</p>
                    <p>- Стоимость выдачи посылки в городе отправителе – от 110 руб.</p>
                    <p>- Стоимость выдачи посылки в г.г. Москва, Санкт-Петербург и др. – от 165 руб. Стоимость доставки «до двери» в городе отправителе – от 235 руб. Стоимость выдачи посылки в г.г. Москва, Санкт-Петербург и др.– от 305 руб.</p>
                    <p>Доставка товаров по России</p>
                    <p>Доставка товара по России производится по действующим тарифам, указанным на сайте www.edostavka.ru</p>
                    <p>C более подробной информацией по тарифам, срокам доставки и списком городов в которых мы можем принимать отправления с наложенным платежом можно ознакомиться в отделе продаж СДЭК.</p>
                    <p>Международная доставка</p>
                    <p>Доставка товара производится с помощью EMS</p>
                    <p>Дополнительные услуги</p>
                    <p>Доставка в вечернее время, в выходные и праздничные дни: Стоимость доставки составит плюс 300 руб. к основному тарифу. При этом заявка должна быть оформлена до 17.00 последнего рабочего дня перед выходным/праздничным днем.</p>
                    <p>Ожидание курьера свыше 15 минут (но не более часа) оплачивается дополнительно в размере 170 рублей.</p>
                </div>
            </div>
            <MoreProducts />
            <Social light />
        </div>
    )
}

export default Doc4Page;