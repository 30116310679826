import ym from 'react-yandex-metrika';
import { useContext } from "react";
import { Link } from "react-router-dom";
import { IProduct } from "../api/type";
import LoadingSpinner from "./LoadingSpinner";
import { ProductContext } from "../context/ProductContext";
import { useTranslation } from "react-i18next";
import { CDN_BASE } from "..";

const shorten = (text: string, limit: number) => {
    return text.length > limit
        ? text.substring(0, limit) + "..."
        : text
}

export const DashboardProduct: React.FC<IProduct> = (product) => {
    const { t, i18n } = useTranslation();
    return (
        <article className="dashboard-product">
            <div className="image" style={{
                backgroundImage: `url(${CDN_BASE + "/" + product[i18n.language === "ru" ? "cover" : "cover_en"]})`
            }}  />
            <div className="details">
                <h3 className="title">{product[i18n.language === "ru" ? "title_ru" : "title_en"]}</h3>
                <p className="description">{shorten(product[i18n.language === "ru" ? "description_ru" : "description_en"], 200)}</p>
                <Link className="btn-link" to={`/dashboard/${product._id}`} onClick={() => ym('hit', `dashboard-product-${product._id}`)}>{t("watch_cta")}</Link>
            </div>
        </article>
    )
}

const Product: React.FC<IProduct> = (product) => {
    const { t, i18n } = useTranslation();
    return (
        <Link to={`/catalog/${product._id}`} className="product" onClick={() => ym('hit', `cart-product-${product._id}`)}>
            <div className="image" style={{
                backgroundImage: `url(${CDN_BASE + "/" + product[i18n.language === "ru" ? "cover" : "cover_en"]})`,
                backgroundColor: product.cover ? "none" : "lightgray",
            }} />
            <div className="details">
                <h3 className="title">{product[i18n.language === "ru" ? "title_ru" : "title_en"]}</h3>
                <div className="price">{product.price} ₽</div>
                {/* <Link className="btn-link" to={`/catalog/${product._id}`}>{t("learn_more_cta")}</Link> */}
            </div>
        </Link>
    )
}

export const MoreProducts: React.FC<{}> = () => {
    const { products, isLoaded } = useContext(ProductContext);
    const { t } = useTranslation();

    return (
        <section className="products-list">
            <div className="container">
                <h1 className="heading-1">{t("more_products_heading")}</h1>
                <div className="products-wrapper">
                    {
                        isLoaded ? 
                            products.slice(0,4).map(p => <Product key={p._id} { ...p } />)
                            :
                            <LoadingSpinner /> 
                    }
                </div>
            </div>
        </section>
    )
}

const ProductsList: React.FC<{products: IProduct[]}> = ({products}) => {
    const { t } = useTranslation();

    return (
        <section className="products-list">
            <div className="container">
                <h1 className="heading-1">{t("all_products_heading")}</h1>
                <div className="products-wrapper">
                    {
                        products.map(p => <Product key={p._id} { ...p } />)
                    }
                </div>
            </div>
        </section>
    )
}

export default ProductsList;