import { useCallback, useContext, useMemo } from "react";
import { IProduct } from "../api/type";
import Breadcrumb from "./Breadcrumb";
import { ProductContext, ProductDispatchContext } from "../context/ProductContext";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import ProductLocked from "./ProductLocked";
import { CDN_BASE } from "..";

const ProductFeatures: React.FC<{features: string[]}> = ({features}) => {
    return (
        <div className="product-features">
            {
                features.map((feature, i) => (
                    <div key={`f${i}`} className="feature">
                        <img alt="" src="/icon/heart.svg" />
                        <span>{feature}</span>
                    </div>
                ))
            }
        </div>
    )
}

const ProductInfo: React.FC<{product: IProduct}> = ({product}) => {
    const { t, i18n } = useTranslation();
    const { cart } = useContext(ProductContext);
    const { user } = useContext(AuthContext);
    const dispatch = useContext(ProductDispatchContext);
    const navigate = useNavigate();

    const user_purchased = user?.products.map(prod => prod._id) || [];

    const purchased = user_purchased.includes(product._id);
    const linked_purchased = product.linked_product ? user_purchased.includes(product.linked_product) : true;

    const path = [
        {
            title: t("nav_home"),
            link: "/"
        },
        {
            title: t("nav_catalog"),
            link: "/catalog"
        },
        {
            title: product[i18n.language === "ru" ? "title_ru" : "title_en"]
        }
    ]

    const in_cart = cart.includes(product._id);

    const toggleCart = useCallback(() => {
        dispatch({
            type: in_cart ? "CART_REMOVE" : "CART_ADD",
            item: product._id
        })
    }, [product, in_cart, dispatch]);

    const cover = useMemo(() => {
        return i18n.language === "ru"
            ? product.cover
            : product.cover_en
                ? product.cover_en
                : product.cover
    }, [i18n.language])

    const preview = useMemo(() => {
        return i18n.language === "ru"
            ? product.preview
            : product.preview_en
                ? product.preview_en
                : product.preview
    }, [i18n.language])

    console.log(preview);

    return (
        <section className="product-info">
            <div className="container">
                <Breadcrumb path={path} />
                <h2 className="product-title">{product[i18n.language === "ru" ? "title_ru" : "title_en"]}</h2>
                <div className="product-info-wrapper">
                    <div className="product-image" style={{
                        backgroundImage: `url(${CDN_BASE + "/" + cover})`,
                        backgroundColor: product.cover ? "none" : "lightgrey"
                    }} />
                    <div className="product-details">
                        {
                            product.preview
                                ? <>
                                    <video controls style={{width: "100%", borderRadius: "5px", backgroundColor: "black"}}>
                                        <source src={`${CDN_BASE}/${preview}`} />
                                    </video>
                                </>
                                : <></>
                        }
                        <div className="purchase">
                            {
                                purchased ?
                                    <button
                                        className="btn-link highlighted"
                                        onClick={() => navigate(`/dashboard/${product._id}`)}
                                        children={t("watch_cta")}
                                    />
                                    :
                                    linked_purchased ?
                                        <button
                                            className={in_cart ? "btn-link highlighted" : "btn-link"}
                                            children={in_cart ? t("remove_cart_button") : t("add_cart_button")}
                                            onClick={toggleCart}
                                        />
                                        :
                                        <ProductLocked
                                            productId={product.linked_product as string}
                                        />
                            }
                            <p className="price">{product.price} ₽</p>
                        </div>
                        <p className="description">{product[i18n.language === "ru" ? "description_ru" : "description_en"]}</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ProductInfo;