import { useParams } from "react-router-dom";
import { useContext } from "react";
import Reviews from "../components/Reviews";
import Header from "../components/Header";
import Social from "../components/Social";
import ProductInfo from "../components/ProductInfo";
import { MoreProducts } from "../components/Products";
import LoadingSpinner from "../components/LoadingSpinner";
import { ProductContext } from "../context/ProductContext";
import { useTranslation } from "react-i18next";

const NoProductPlaceholder: React.FC = () => {
    const { t } = useTranslation();

    return (
        <div className="no-product-placeholder">
            <img className="icon" src="/icon/face-sad-cry.svg" alt="" />
            <span className="label">{t("product_not_found")}</span>
        </div>
    )
}

const ProductPage: React.FC<{}> = () => {
    const { productId } = useParams();
    const { products, isLoaded } = useContext(ProductContext);

    const product = products.find(product => product._id === productId) || null;

    return (
        <div className="page-wrapper">
            <Header />
            <div className="light_bg">
                {
                    isLoaded ? (product === null) ?
                        <NoProductPlaceholder /> :
                        <ProductInfo product={product} /> :
                        <LoadingSpinner />
                }
            </div>
            <Reviews />
            <MoreProducts />
            <Social light />
        </div>
    )
}

export default ProductPage;