import { useContext, useMemo } from "react";
import Header from "../components/Header";
import Reviews from "../components/Reviews";
import Social from "../components/Social";
import ProductsList from "../components/Products";
import LoadingSpinner from "../components/LoadingSpinner";
import { ProductContext } from "../context/ProductContext";
import BundleSlider from "../components/BundleSlider";

const CatalogPage: React.FC<{}> = () => {
    const { products, isLoaded } = useContext(ProductContext);
    const availableProducts = useMemo(() => products.filter(p => p.active).sort((a, b) => {
        if(a.position > b.position) return 1
        if(a.position < b.position) return -1
        return 0
    }), [products]);

    return (
        <div className="page-wrapper">
            <Header />
            <BundleSlider />
            { 
                isLoaded 
                    ? <ProductsList products={availableProducts} /> 
                    : <LoadingSpinner /> 
            }
            <Reviews />
            <Social />
        </div>
    )
}

export default CatalogPage;