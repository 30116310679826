import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import EN from "./content_en.json";
import RU from "./content_ru.json";

const resources = {
  en: {
    translation: EN,
  },
  ru: {
    translation: RU,
  },
};

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18next;