import axios from "axios";
import {useState, useContext} from "react";
import { useParams } from "react-router-dom"
import { AuthDispatchContext } from "../context/AuthContext";
import { useNavigate } from "react-router";

import Header from "../components/Header";
import Social from "../components/Social";
import { useTranslation } from "react-i18next";

const PasswordResetSubmitPage: React.FC<{}> = () => {
    const { token } = useParams();
    const { t } = useTranslation();
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState("");
    const [passwordRepeat, setPasswordRepeat] = useState("");
    const dispatch = useContext(AuthDispatchContext);
    const navigate = useNavigate();

    function Login(){
        setError(null);
        setLoading(true);
        axios.post("/api/auth/submit-reset-password", {
            token: token,
            password
        }).then(({data}: any) => {
            dispatch({type: "login", user: data.user});
            navigate("/dashboard")
        }).catch((error: any) => {
            setError("error")
        }).finally(() => {
            setLoading(false);
        })
    }
    return (
        <div className="page-wrapper">
            <Header />
            <div className="light-bg full-page-block">
                <div className="auth-form login-form form">
                    <div className="form-title">{t("password_reset_form_title")}</div>
                    <div className="tip">{t("passord-reset-submit-tip")}</div>
                    {error && <div className="auth-error">{error}</div>}
                    <p className="auth-label">{t("form_label_password")}:</p>
                    <input
                        disabled={loading}
                        className="auth-input"
                        type="password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                    />
                    <p className="auth-label">{t("form_label_password_repeat")}:</p>
                    <input
                        disabled={loading}
                        className="auth-input"
                        type="password"
                        value={passwordRepeat}
                        onChange={e => setPasswordRepeat(e.target.value)}
                    />
                    <button
                        disabled={loading || password.length < 8 || passwordRepeat.length < 8 || password !== passwordRepeat}
                        className={`auth-submit ${loading ? "loading" : ""}`}
                        onClick={Login}
                        children={t("set_password")}
                    />
                </div>
            </div>
            <Social />
        </div>
    )
}

export default PasswordResetSubmitPage;