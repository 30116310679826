import { useParams } from "react-router-dom";
import { useContext } from "react";
import Header from "../components/Header";
import Social from "../components/Social";
import { MoreProducts } from "../components/Products";
import LoadingSpinner from "../components/LoadingSpinner";
import { ProductContext } from "../context/ProductContext";
import { useTranslation } from "react-i18next";
import Breadcrumb from "../components/Breadcrumb";
import Player from "../components/Player";
import { AuthContext } from "../context/AuthContext";

const NoProductPlaceholder: React.FC = () => {
    const { t } = useTranslation();

    return (
        <div className="no-product-placeholder">
            <img className="icon" src="/icon/face-sad-cry.svg" alt="" />
            <span className="label">{t("product_not_found")}</span>
        </div>
    )
}


const WatchPage: React.FC<{}> = () => {
    const { user } = useContext(AuthContext);
    const { productId } = useParams();
    const { products, isLoaded } = useContext(ProductContext);
    const product = products.find(product => product._id === productId) || null;
    const { t, i18n } = useTranslation();

    const path = [
        {
            title: t("nav_home"),
            link: "/"
        },
        {
            title: t("nav_dashboard"),
            link: "/dashboard"
        },
        {
            title: product?.[i18n.language === "ru" ? "title_ru" : "title_en"] ?? "Not found"
        }
    ]

    return (
        <div className="page-wrapper">
            <Header />
            <div className="light_bg">
                {
                    isLoaded ? (product === null) ?
                        <NoProductPlaceholder /> :
                        <section className="product-info">
                            <div className="container">
                                <Breadcrumb path={path} />
                                <h2 className="product-title">{product[i18n.language === "ru" ? "title_ru" : "title_en"]}</h2>
                                <div className="product-info-wrapper">
                                    <Player
                                        video={`/api/product/${product._id}/course?lang=${i18n.language}`}
                                        phone={user?.phone as any}
                                    />
                                </div>
                                <div className="product-desc">
                                    <div className="label">{t("about_product")}:</div>
                                    <p className="desc">{product[i18n.language === "ru" ? "description_ru" : "description_en"]}</p>
                                </div>
                            </div>
                        </section> :
                        <LoadingSpinner />
                }
            </div>
            <MoreProducts />
            <Social light />
        </div>
    )
}

export default WatchPage;