import Header from "../../components/Header";
import Breadcrumb from "../../components/Breadcrumb";
import { MoreProducts } from "../../components/Products";
import Social from "../../components/Social";

const path = [
    {
        title: "Главная",
        link: "/"
    },
    {
        title: "Политики и оферта",
        link: "/policy"
    },
    {
        title: "Публичная оферта"
    }
]

const Doc1Page: React.FC<{}> = () => {
    return (
        <div className="page-wrapper">
            <Header />
            <div className="container light-bg">
                <Breadcrumb path={path} />
                <div className="title-wrapper">
                    <h2 className="title">Публичная оферта</h2>
                </div>
                <div className="doc">
                    <p>Настоящий договор является публичным договором (публичной офертой на основании пункта 2 статьи 437 Гражданского Кодекса Российской Федерации) индивидуального предпринимателя Хворостин Ильи Андреевича, именуемого в дальнейшем «Исполнитель», действующего на основании Свидетельства о государственной регистрации физического лица в качестве индивидуального предпринимателя (ОГРНИП 312774633300557) от 28 ноября 2012 г., с любым физическим лицом, выразившем готовность воспользоваться услугами Исполнителя и принявшему условия настоящего договора (далее - Договор), именуемый в дальнейшем «Заказчик».</p>
                    <p><b>1.</b> Определения и термины</p>
                    <p><b>1.1.</b> Оферта – формальное предложение некоторого лица (оферента) определенному лицу (акцептанту) заключить сделку (договор) с указанием всех необходимых для этого условий.</p>
                    <p><b>1.2.</b> Акцепт Оферты - полное и безоговорочное принятие Оферты путем осуществления 100% предварительной оплаты за оказываемую Информационную услугу.</p>
                    <p><b>1.3.</b> Исполнитель – ИП Хворостин Илья Андреевич, предоставляющий Информационные услуги Заказчику на условиях настоящей Оферты.</p>
                    <p><b>1.4.</b> Заказчик - лицо, осуществившее Акцепт Оферты на изложенных в ней условиях.</p>
                    <p><b>1.5.</b> Договор на оказание информационных услуг (далее – Договор) – договор между Заказчиком и Исполнителем на предоставление Информационных услуг, который заключается посредством Акцепта настоящей Оферты.</p>
                    <p><b>1.6.</b> Сайт (ресурс) – это совокупность связанных между собой веб-страниц, размещенных в сети Интернет по уникальному адресу (URL): http://viksi.me.</p>
                    <p><b>1.7.</b> Информация – любые сведения, любой контент, размещенные на Ресурсе.</p>
                    <p><b>1.8.</b> Контент Сайта - информационные материалы, права на которые принадлежат Исполнителю и / или его контрагентам, размещаемые силами Исполнителя на Сайте, а именно: авторские программы тренингов, видеоматериалы, обучающие статьи, любые изображения, а также любые другие материалы, размещенные на Сайте либо высылаемые Заказчику и применяемые для оказания Услуг.</p>
                    <p><b>1.9.</b> Программа – комплекс информационных материалов рекомендательного характера.</p>
                    <p><b>1.10.</b> Пользователь Ресурса – любое лицо, принявшее условия настоящего Соглашения и осуществляющее доступ к Ресурсу посредством сети Интернет.</p>
                    <p><b>1.11.</b> Учетная запись (аккаунт) - запись, хранящаяся на Сайте, содержащая сведения, необходимые для идентификации Пользователя при предоставлении доступа к Сайту, информацию для Авторизации и учета на Сайте. К такой записи, в том числе относятся имя Пользователя и пароль (или другие аналогичные средства аутентификации). Все действия, совершаемые конкретным лицом на сайте после приобретения платных информационно-консультативных услуг, производятся только с того аккаунта, доступ которому был открыт для оказания услуги.</p>
                    <p><b>1.12.</b> Информационные услуги - услуги Исполнителя по предоставлению ограниченного доступа Заказчик к Сайту в соответствии с условиями Оферты для получения оплаченных ранее услуг на интернет-ресурсе. Форма оказания Услуг, а так же сроки и необходимость предоставления сопутствующих информационных услуг определяются Исполнителем самостоятельно.</p>
                    <p><b>2.</b> Общие положения</p>
                    <p><b>2.1.</b> Данный документ является публичной Офертой ИП Хворостина Ильи Андреевича (в дальнейшем именуемого “Исполнитель”) и содержит все существенные условия по оказанию информационно-консультационных услуг (в соответствии со статьей 435 и частью 2 статьи 437 Гражданского кодекса РФ).</p>
                    <p><b>2.2.</b> Любое физическое лицо, имеющее намерение приобрести информационно- консультативную услугу и оплатить получение информационно- консультативной услуги, именуется “Заказчиком”.</p>
                    <p><b>2.3.</b>Полным и безоговорочным акцептом настоящей публичной оферты считается факт оплаты Заказчиком услуг, предоставляемых Исполнителем в соответствии с условиями Договора, путем нажатия на кнопку «КУПИТЬ» на сайте Исполнителя по адресу http://viksi.me. Так же «заказчик» безоговорочно подтверждает свое совершеннолетие, осуществляя любые действие с любым разделом сайта.</p>
                    <p><b>2.4.</b> Предоставление услуг считается оказанным при получении электронного товара</p>
                    <p><b>3.</b> Предмет Договора</p>
                    <p><b>3.1.</b> Предметом Договора является возмездное предоставление Исполнителем Услуг по предоставлению информации рекомендательного характера в соответствии с выбором Заказчика в целях достижения Заказчиком необходимых физических показателей. (далее - «Услуги»).3.1.1. Доступ к закрытым разделам Сайтуа, Интернет-сервиса предоставляется на платной основе исключительно лицам, достигшим 18 лет. Отношения, носящие безвозмездный</p>
                    <p>характер, урегулированы отдельным документом.</p>
                    <p><b>3.2.</b> Содержание Услуг:</p>
                    <p><b>3.2.21</b> Предоставление информации и материалов (программа, видео курс, веб- консультации, вебинары, электронные книги и курсы) в зависимости от выбранной Заказчиком Программы</p>
                    <p><b>3.3.</b> Исполнитель не предоставляет образовательные услуги официального учебного заведения и не занимается выдачей каких-либо сертификатов и лицензий. Исполнитель НЕ занимается лечебной или какой-либо другой медицинской практикой. Исполнитель не оказывает услуги тренажерного или аэробного зала, не предоставляет услуги персонального тренера.</p>
                    <p><b>3.4.</b> Заказчик полностью осознает, что результат оказания Услуг целиком зависит от того, насколько старательно и добросовестно им будет выполняться Программа. Ввиду того, что Исполнитель не имеет возможности проконтролировать правильность и добросовестность выполнения Программы, Заказчик полностью осознает, что Исполнитель ни при каких условиях не может нести и не несет ответственности за результат применения Программы Заказчиком.</p>
                    <p><b>3.5.</b> В случае, если Заказчик нарушает предлагаемые Исполнителем программы тренировок и питания, в том числе, нарушает график тренировок и / или пропускает тренировки и / или не выполняет рекомендуемые упражнения согласно предлагаемому графику, Услуги считаются оказанными Исполнителем, и уплаченные денежные средства не подлежат возврату Заказчику.</p>
                    <p><b>3.6.</b> Исполнитель имеет право в любой момент изменять даты и время проведения мероприятий, а так же условия настоящей публичной Оферты в одностороннем порядке без предварительного согласования с Заказчиком, обеспечивая при этом публикацию измененных условий не менее чем за один день до их ввода в действие.</p>
                    <p><b>3.7.</b> Исполнитель не несет ответственности за своевременность, качество и сроки работ подрядчиков и иных лиц, оказывающие услуги по субагентским и агентским договорам. Ответственность за надлежащее оказание этих услуг возлагается на лиц, фактически их исполняющие.</p>
                    <p><b>4.</b> Права и обязанности Исполнителя</p>
                    <p>Исполнитель обязан:</p>
                    <p><b>4.1.</b> Надлежащим образом оказать Услуги по настоящему Договору.</p>
                    <p><b>4.2.</b> Оказать Услуги непосредственно после поступления на счет Исполнителя полной стоимости Услуг.</p>
                    <p><b>4.3.</b> Услуги считаются оказанными надлежащим образом и принятыми Заказчиком, если в течение 7 дней после оказания Услуг Исполнитель не получил от Заказчика письменных мотивированных возражений.</p>
                    <p>По истечении срока, указанного выше, претензии Заказчика относительно недостатков Услуг, в том числе по количеству (объему), стоимости и качеству не принимаются.</p>
                    <p>Права Исполнителя:</p>
                    <p><b>4.4.</b> Самостоятельно определять способы и методы оказания Услуг без предварительного согласования с Заказчиком.</p>
                    <p><b>5.</b> Права и обязанности Заказчика</p>
                    <p>Заказчик обязан:</p>
                    <p><b>5.1.</b> Предоставлять Исполнителю достоверные сведения и данные, необходимые для оказания Услуг.</p>
                    <p><b>5.2.</b> Не воспроизводить, не копировать, не распространять, а также не использовать любым иным способом в каких бы то ни было целях содержание Программы, за исключением личного использования в рамках достижения цели Договора.</p>
                    <p><b>5.3.</b> Не осуществлять действия по размещению в сети Интернет любых незаконных, дискредитирующих или носящих угрозу сообщений, в том числе, сообщений, противоречащих законодательству Российской Федерации.</p>
                    <p><b>5.4.</b> Не осуществлять любые способы агитации и подстрекательства иных лиц в целях использования каких-либо конкурентных для Исполнителя продуктов или услуг, не размещать в сети Интернет информацию, каким-либо образом дискредитирующую Исполнителя, в том числе, данные о недостижении Заказчиком каких-либо желаемых им результатов.</p>
                    <p><b>5.5.</b> Выполнять и полностью соблюдать программы питания и тренировок, предоставленные Исполнителем.</p>
                    <p>Права Заказчика:</p>
                    <p><b>5.6.</b> Требовать от Исполнителя надлежащего оказания Услуг.</p>
                    <p><b>6.</b> Условия и порядок предоставления услуг</p>
                    <p><b>6.1.</b> Информационно-консультационные услуги предоставляются в полном объеме при условии их 100% (сто процентов) оплаты Заказчиком.</p>
                    <p><b>6.2.</b> Факт приобретения Информационно-консультативных услуг является безоговорочным принятием Заказчиком данного Договора-оферты. Заказчик, воспользовавшийся услугами Исполнителя, рассматривается как лицо, вступившее с Исполнителем в договорные отношения.</p>
                    <p><b>6.3.</b> Заявка на участие в мероприятиях Исполнителя оформляется на странице сайта http://viksi.me. В момент заказа услуги Заказчику выставляется счет для оплаты любым из способов, доступных на сайте Исполнителя. Факт оплаты «электронного» (без представления Счета на бумажном и иных носителях) счета подтверждает полное и безоговорочное согласие Заказчика с Офертой Исполнителя.</p>
                    <p><b>6.4.</b> Ссылки для участия в мероприятиях Заказчику высылаются на е-mail только после поступления 100% денежных средств Заказчика на счёт Исполнителя.</p>
                    <p><b>6.5.</b> В случае, если Заказчик оплатил свой заказ, но доступ на ресурсе http:// viksi.me либо через e-mail не был предоставлен к оплаченной Услуге Исполнителя в течении 3 рабочих дней с момента зачисления платежа, необходимо сообщить о данном факте на e-mail: 666viksi666@gmail.com, и предоставить подтверждающий осуществление платежа документ..</p>
                    <p><b>6.7.</b> Исполнитель обязуется предоставить Заказчику информационно- консультационные услуги в форме проведения оплаченной Заказчиком информационно-образовательной программы в срок, указанный на странице описания конкретной информационно-консультативной услуги, расположенной на сайте http://viksi.me.</p>
                    <p><b>6.8.</b> Настоящий договор имеет силу Акта об оказании услуг. Отсутствие требования о возврате денежных средств означает, что услуги оказаны полностью, в срок и надлежащего качества. Приемка производится без подписания Актов на бумажном, или иных носителях.</p>
                    <p><b>6.9.</b> Исполнитель оставляет за собой право в одностороннем порядке прекратить (отказать) предоставление Услуг Заказчику без права на возврат денежных средств в случае нарушения правил поведения на вебинарах, интернет-семинарах или иных программах и Услугах, оказываемых Исполнителем.</p>
                    <p><b>6.10.</b> В число случаев нарушения правил, являющихся поводом в отказе дальнейшего предоставления Услуги входит, включая, но не ограничиваясь этим, следующее:</p>
                    <p><b>–</b> разжигание межнациональных конфликтов, отвлечение участников от темы вебинара, интернет-семинара и других Услуг, оказываемых Заказчику или группе Заказчиков;</p>
                    <p><b>–</b> спам;</p>
                    <p><b>–</b> размещение рекламы своих или чужих услуг;</p>
                    <p><b>–</b> нецензурные высказывания, хамство, оскорбления, внесение негатива в группе, призывы к массовому отказу от участия в интернет-семинарах, вебинарах и других услугах, оказываемых Исполнителем.</p>
                    <p><b>6.11.</b> Услуги предоставляются для личного использования Заказчиком. Запрещается передавать записи, доступы, логины и пароли для доступа в информационно- образовательную программу третьим лицам для их совместного использования без специального на то разрешения (согласования) Исполнителя. 6.12. Исполнитель оставляет за собой право в одностороннем порядке отключать от участия в интернет-семинаре (вебинаре) или другой Услуге, оказываемой исполнителем) лицо, совершившее несанкционированный, несогласованный доступ.</p>
                    <p><b>6.13.</b> Заказчику запрещается распространять (публиковать, размещать на Интернет- сайтах, копировать, передавать или перепродавать третьим лицам) в коммерческих или некоммерческих целях предоставляемую Исполнителем Заказчику информацию и материалы в рамках настоящего договора Оферты, создавать на ее основе информационные продукты, а также использовать эту информацию каким-либо иным образом, кроме как для личного пользования.</p>
                    <p><b>6.14.</b> Исполнитель оставляет за собой право осуществлять запись проводимых информационно-консультативных Услуг.</p>
                    <p><b>6.15.</b> Участнику, получающему Услугу, запрещается осуществлять запись информационно-консультативной Услуги без специального на то разрешения Исполнителя.</p>
                    <p><b>7.</b> Финансовые условия</p>
                    <p><b>7.1.</b> Услуги предоставляются при условии их 100% оплаты Заказчиком. Оплата Услуг оформляется при заключении Договора на Сайте Исполнителя. Оплата производится через RBK Money. В случае если оплата не будет осуществлена, Договор не считается заключенным.</p>
                    <p><b>8.</b> Гарантии и ответственность</p>
                    <p><b>8.1.</b> Исполнитель не оказывает услуги медицинского учреждения.</p>
                    <p><b>8.2.</b> Заказчик полностью осознает, что бывают случаи скрытых проблем со здоровьем, о которых может быть неизвестно самому Заказчику. Заказчик обязан понимать, что Услуги предназначены для относительно здоровых людей.</p>
                    <p><b>8.3</b> Заказчик полностью осознает, что осуществляемые им самостоятельно в рамках разработанных Исполнителем программ тренировки, предполагающие использование какого-либо спортивного оборудования, а также любые физические упражнения, могут повлечь за собой получение повреждений. Заказчик понимает это и добровольно берет на себя ответственность за получение любого вида повреждений, от которых может пострадать физически и / или морально. Заказчик согласен, что Исполнитель, либо его представители не несут какую-либо ответственность за повреждения, которые могут быть получены в процессе тренировок.</p>
                    <p><b>8.4.</b> Исполнитель НЕ несет за недостижение ожидаемых Заказчиком им результатов, либо несоответствие полученного результата ожиданиям Заказчика, поскольку, не имеет возможности полностью контролировать выполнение Исполнителем разработанных программ.</p>
                    <p><b>8.5.</b> В случае нарушения Заказчиком п. 5.2 Договора, по требованию Исполнителя Заказчик уплачивает штраф в размере 1 000 000 (Один миллион) рублей за каждый случай нарушения. Кроме того, в таком Исполнитель имеет право случае в одностороннем порядке расторгнуть Договор без компенсации Заказчику уплаченных денежных СРЕДСТВ.</p>
                    <p><b>8.6.</b> В случае нарушения Заказчиком п.п. 5.3, 5.4 Договора, Исполнитель имеет право в одностороннем порядке расторгнуть Договор без компенсации Заказчику уплаченных денежных СРЕДСТВ и без направления соответствующего уведомления</p>
                    <p><b>8.7.</b> Заказчик обязан обеспечить бесперебойную работу Интернет-канала, оборудования и программного обеспечения со своей стороны в течение оказания Услуги Исполнителем.</p>
                    <p><b>8.8.</b> Исполнитель не несет ответственности за невозможность обслуживания Заказчика по причинам, связанным с нарушением работы Интернет-канала, оборудования или программного обеспечения со стороны Заказчика.</p>
                    <p><b>8.9.</b> В случае, если по какой-либо причине Исполнитель не оказывает Услуги в оговоренные сроки, ответственность Исполнителя ограничивается исключительно проведением соответствующего вебинара (или серии вебинаров, иных Услуг) в новые сроки.</p>
                    <p><b>8.10.</b> Исполнитель не несет ответственности за то, каким образом Заказчик использовал полученную в ходе информационно-консультационного обслуживания информацию, и за результаты ее использования Заказчиком.</p>
                    <p><b>8.11.</b> Совокупная ответственность Исполнителя по договору Оферты, по любому иску или претензии в отношении договора Оферты или его исполнения ограничивается суммой платежа, уплаченного Исполнителю Заказчиком по договору Оферты, и не может включать иные расходы, связанные с осуществлением деятельности или отсутствием таковой у Заказчика.</p>
                    <p><b>8.12.</b> За невыполнение или ненадлежащее выполнение обязательств по настоящей публичной Оферте Стороны несут ответственность в соответствии с действующим законодательством РФ.</p>
                    <p><b>8.13.</b> Заказчик несет полную ответственность за соблюдение требований законодательства РФ, в том числе законодательства о рекламе, о защите авторских и смежных прав, об охране товарных знаков и знаков обслуживания, но не ограничиваясь перечисленным, включая полную ответственность за содержание и форму материалов.</p>
                    <p><b>8.14.</b> Исполнитель не несет ответственности перед Заказчиком за любой убыток или ущерб, понесенный Пользователем в результате удаления, сбоя или невозможности сохранения какого-либо Содержания и иных коммуникационных данных, содержащихся на Ресурсе или передаваемых через такой Ресурс.</p>
                    <p><b>8.15.</b> Исполнитель не несет ответственности за любые прямые или косвенные убытки, произошедшие из-за: использования либо невозможности использования Сайта, либо отдельных сервисов; несанкционированного доступа к коммуникациям Заказчика; заявления или поведение любого третьего лица на сайте.</p>
                    <p><b>8.16.</b> Ответственность Исполнителя ограничена стоимостью оказания Услуг в соответствии с настоящим Договором.</p>
                    <p><b>9.</b> Обработка персональных данных Заказчика</p>
                    <p><b>9.1.</b> Предоставляя необходимую для оказания Услуг информацию, Заказчик предоставляет Исполнителю свои персональные данные. Настоящим Заказчик выражает свое Согласие на обработку персональных переданных им в соответствии Данных с Федеральным законом No152-ФЗ от 27 июля 2006 года «О персональных данных».</p>
                    <p><b>9.2.</b> Исполнитель при обработке персональных данных Заказчика обязуется принять все предусмотренные действующим законодательством Российской Федерации меры для их защиты от несанкционированного доступа.</p>
                    <p><b>9.3.</b> Заказчик полностью осознает, что в результате определенных обстоятельств (к примеру, размещение достигнутых Заказчиком результатов обучения на Сайте, а также иные случаи) персональные данные Заказчика могут стать доступными и другим лицам.</p>
                    <p><b>10.</b> Безопасность учетных данных и блокировка аккаунтов пользователей</p>
                    <p><b>10.1.</b> Регистрация Пользователя на Сайте осуществляется путем заполнения соответствующей регистрационной формы.</p>
                    <p><b>10.2.</b> Пароль от учетной записи высылается пользователю на e-mail, указанный при регистрации.</p>
                    <p><b>10.3.</b> После получения первичного пароля – в целях безопасности – рекомендуем Вам сменить его в личном кабинете на сайте http://viksi.me. Администрация ресурса не несет ответственности за сохранность логинов/паролей, используемых пользователями.</p>
                    <p><b>10.4.</b> Любые действия, совершенные из личного кабинета (после авторизации) на сайте признаются действиями, совершенными конкретным пользователем и накладывают на этого пользователя обязательства и ответственность (в случае разглашения, распространения, передачи частично или полностью информации, ставшей доступной вследствие оплаты конкретных информационно- консультативных услуг).</p>
                    <p><b>10.5.</b> В случае, если Пользователю станет известно о любом несанкционированном использовании его пароля, логина или реквизитов личного аккаунта, Пользователь обязуется незамедлительно уведомить об этом, выслав соответствующее электронное сообщение по адресу: 666viksi666@gmail.com.</p>
                    <p><b>10.6</b> Указывая свои данные на сайте, путем заполнения соответствующих регистрационных форм, Пользователь дает свое согласие на сбор, обработку, хранение своих персональных данных.</p>
                    <p><b>10.7.</b> Исполнитель гарантирует сохранность полученных данных и отсутствие доступа к ним со стороны третьих лиц.</p>
                    <p><b>11.</b> Права на результаты интеллектуальной деятельности</p>
                    <p><b>11.1.</b> Исключительные права на Программы, их содержание, принадлежат Исполнителю, и / или его контрагентам.</p>
                    <p><b>11.2.</b> Заказчик имеет право использовать Программы исключительно в личных, некоммерческих целях для достижения цели Договора.</p>
                    <p><b>11.3.</b> Материалы Сайта, содержание Программ питания и тренировок предназначены только для частного использования Заказчиком. Любое</p>
                    <p>несанкционированное использование информации любым образом для любых целей, независимо от того, имеется ли при этом даже потенциальная возможность извлечения коммерческой выгоды, или нарушение (прямое или косвенное) авторских и/или смежных прав, или искажение и/или фальсификация информации, запрещено. Также запрещены: перепечатка, тиражирование, распространение или публикация на общедоступных ресурсах, независимо от способа или метода, без прямого письменного согласия правообладателя. Нарушение указанных правил будет расцениваться нарушением четвертой части Гражданского кодекса РФ, иного законодательства РФ.</p>
                    <p><b>12.</b> Условия и порядок возврата денежных средств за информационно- консультативные услуги.</p>
                    <p><b>12.1.</b> Заказчик имеет право оказаться от предоставления оплаченных информационно- консультативных услуг и потребовать возврата денежных средств в следующих случаях:</p>
                    <p><b>–</b> Скайп-консультации, которую не удалось провести,</p>
                    <p><b>–</b> Участие в фитнес-марафоне или любой программе, в которой предоставляется услуга только после анкетирования.</p>
                    <p><b>–</b> Заказчик оплатил участие в фитнес-марафоне или участие в любой программе с анкетированием, получил анкету, но не отправил её в течении 30 календарных дней с момента покупки. В этом случае услуга не предоставлена и денежные средства возвращаются в полном объёме, если заявка на возврат подана в течении 30 календарных дней с момента покупки.</p>
                    <p><b>–</b> Заказчик, оплативший участие в программе с анкетированием, но не воспользовавшийся услугой, имеет право на участие в таком же проекте с новой даты старта в течении 90 календарных дней по установленному тарифу на день обращения о возобновлении участия. Если стоимость ниже – заказчику ничего не возвращается (промо-акции, скидки не учитываются). Если стоимость выше оплаченной, заказчик обязуется оплатить разницу для оказания услуги в полном размере.</p>
                    <p><b>12.2.</b> Электронные книги и курсы возврату не подлежат.</p>
                    <p><b>12.3.</b> Заявки на возврат денежных средств принимаются и рассматриваются в течении 7 (семи) дней с момента, объявленного днем начала оказания Услуги. По истечении указанного времени заявки на возврат денежных средств не принимаются, а денежные средства не возвращаются, что безоговорочно принимается Сторонами.</p>
                    <p><b>12.5.</b> Для осуществления возврата денежных средств Заказчик должен прислать в установленный срок заявку на возврат на электронный адрес службы поддержки 666viksi666@gmail.com.</p>
                    <p>Обращаем Ваше внимание, что денежные средства возвращаются только на те реквизиты, с которых фактически был совершен платеж.</p>
                    <p><b>12.7.</b> Срок рассмотрения заявки на возврат не может превышать 10 (десять) календарных дней.</p>
                    <p><b>12.8.</b> Срок возврата денежных средств Заказчику осуществляется в течении 14 (четырнадцати) календарных дней с момента принятия положительного решения об осуществлении Возврата.</p>
                    <p><b>12.9.</b> В случае отказа в возврате денежных средств Заказчик будет проинфомирован об этом по адресу электронной почты, с которой осуществлялась заявка на Возврат с указанием причины отказа.</p>
                    <p><b>12.10.</b> Исполнение либо ненадлежащее исполнение Заказчиком условий получения Информационной услуги (включая, но не ограничиваясь следующим: отсутствие на онлайн-вебинарах, семинарах, отказ выполнения заданий Исполнителя в ходе оказания Информационной услуги) является для Исполнителя основанием для отказа в возврате денежных средств, внесенных Заказчиком в пользу Исполнителя за оказанную Информационную услугу, чем бы не обосновывалось данное требование, что безоговорочно принимается Сторонами.</p>
                    <p><b>12.11.</b> В случае направления запроса Заказчиком в адрес Исполнителя заявки на возврат денежных средств через любые каналы связи и в любой (письменной или устной форме) доступ к оказываемой услуге (интернет-семинару, тренингу, личной консультации) приостанавливается до принятия решения о возврате денежных средств Заказчику или обоснованном отказе в возврате денежных средств согласно условий Оферты.</p>
                    <p><b>12.12.</b> В случае отказа Заказчиком от полного выполнения требований Исполнителя в отношении п.6.5. настоящей Оферты, в рассмотрении заявки на возврат и возврате денежных средств будет отказано.</p>
                    <p><b>13.</b> Порядок разрешения споров</p>
                    <p><b>13.1.</b> В случае возникновения споров, связанных с исполнением настоящего Договора, Сторонами будет применен досудебный порядок урегулирования спора. 13.2. В случае невозможности разрешения спора путем переговоров, он будет передан Сторонами на рассмотрение Арбитражного суда.</p>
                    <p><b>14.</b> Изменение условий Оферты</p>
                    <p><b>14.1.</b> Изменение условий Оферты производится Исполнителем в одностороннем порядке.</p>
                    <p><b>14.2.</b> Обновленная версия Оферты публикуется на Сайте Исполнителя не позднее, чем за 1 (один) день до вступления новых условий Оферты в силу.</p>
                    <p><b>15.</b> Заключительные условия</p>
                    <p><b>15.1.</b> По всем вопросам и претензиям Заказчик может обращаться по адресу: 666viksi666@gmail.com.</p>
                    <p><b>15.2.</b> Если по тем или иным причинам одна или несколько норм настоящей Оферты являются недействительной или не имеющими юридической силы, это не оказывает влияния на действительность или применимость остальных норм.</p>
                    <p><b>15.3.</b> К настоящему соглашению применяется законодательство Российской Федерации.</p>
                    <p><b>15.4.</b> Ничто в Оферте не может пониматься как установление между Заказчиком и Исполнителем агентских отношений, отношений товарищества, отношений по совместной деятельности, отношений личного найма, либо каких-то иных отношений, прямо не предусмотренных Офертой.</p>
                    <p><b>15.5.</b>Соглашаясь с условиями данной Оферты, Заказчик подтверждает и гарантирует Исполнителю, что:</p>
                    <p><b>15.5.1.</b> Заказчик укажет достоверную информацию о себе, в том числе при создании учетной записи (аккаунта).</p>
                    <p><b>15.5.2.</b> Заказчик:</p>
                    <p><b>–</b> полностью ознакомился с условиями Договора-оферты;</p>
                    <p><b>–</b> полностью понимает предмет Договора-оферты;</p>
                    <p><b>–</b> полностью понимает значение и последствия своих действий в отношении</p>
                    <p>заключения и исполнения настоящего Договора-оферты.</p>
                    <p><b>–</b> подтверждает свое совершеннолетие.</p>
                    <p><b>15.6.</b> Договор представляет собой полную договоренность между Исполнителем и Заказчиком.</p>
                    <p><b>15.7.</b> Исполнитель не принимает на себя никаких условий и обязательств в отношении предмета Договора, кроме указанных в нем.</p>
                    <p><b>15.8.</b> Вопросы, не урегулированные Договором, регулируются действующим законодательством Российской Федерации.</p>
                    <p><b>15.9.</b> В случае возникновения споров и разногласий Стороны приложат все усилия, чтобы устранить их путём переговоров или в претензионном порядке. Срок рассмотрения претензии - 10 (десять) дней с момента ее получения.</p>
                    <p><b>15.10.</b> В случае невозможности разрешения споров и разногласий путем переговоров или в претензионном порядке, Стороны вправе передать спор на рассмотрение в суд общей юрисдикции по месту нахождения Исполнителя. Споры между Сторонами разрешаются в соответствии с действующим законодательством Российской Федерации.</p>
                    <p><b>15.11.</b> Договор вступает в силу с момента акцепта Заказчиком настоящей публичной оферты и действует до полного исполнения Сторонами принятых на себя обязательств.</p>
                    <p><b>15.12.</b> Договор-оферта вступает в силу с момента согласия Заказчика с условиями настоящей Оферты (в момент оплаты Услуг Исполнителя), срок действия которого не ограничен данной Офертой.</p>
                    <p><b>11.</b> Реквизиты Исполнителя:</p>
                    <p><b>11.1</b> Реквизиты Исполнителя:</p>
                    <p>Индивидуальный предприниматель Хворостин Илья Андреевич расчетный счет No 40802810140020011331</p>
                    <p>в ПАО СБЕРБАНК РОССИИ</p>
                    <p>БИК 044525225 корреспондентский счет No 30101810400000000225 ).</p>
                </div>
            </div>
            <MoreProducts />
            <Social light />
        </div>
    )
}

export default Doc1Page;