import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { useContext, useEffect, useMemo, useState } from "react";
import { Autoplay, Pagination } from "swiper";
import { Bundle, IProduct } from "../api/type";

import 'swiper/css';
import "swiper/css/pagination";

import LoadingSpinner from './LoadingSpinner';
import { AuthContext } from '../context/AuthContext';
import { useTranslation } from 'react-i18next';

import { CDN_BASE } from '..';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import FormInput from './FormInput';

const BundleProduct: React.FC<IProduct> = (product) => {
    const { i18n } = useTranslation();
    return (
        <Link
            className='bundle-product'
            title={i18n.language === "ru" ? product.title_ru : product.title_en}
            to={`/catalog/${product._id}`}
            style={{
                backgroundImage: `url(${CDN_BASE + "/" + product[i18n.language === "ru" ? "cover" : "cover_en"]})`,
            }}
        />
    )
}

const BundleItem: React.FC<Bundle & {onSelect: (id: string) => void}> = (bundle) => {
    const { isLoggedIn, isLoaded } = useContext(AuthContext);
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    const basePrice = bundle.products.reduce((prev, curr) => prev + curr.price, 0);
    const totalPrice = basePrice * (100 - bundle.discount) / 100

    return (
        <div className="bundle-item-wrapper">
            <div className='bundle-title-wrapper'>
                <h4 className='bundle-title'>{i18n.language === "ru" ? bundle.title_ru : bundle.title_en}</h4>
                <div className='bundle-discount'>-{bundle.discount}%</div>
            </div>
            <div className='bundle-products-wrapper'>
                {
                    bundle.products.map(product => 
                        <BundleProduct 
                            {...product}
                            key={bundle._id + product?._id} 
                        />
                    )
                }
            </div>
            <div className='buy-button-wrapper'>
                <button
                    className={`buy-button`}
                    onClick={() => isLoggedIn ? bundle.onSelect(bundle._id) : navigate("/login")}
                >{t("bundle_buy")}</button>
                <div className='bundle-price'>
                    <span className='price'>{totalPrice.toLocaleString(i18n.language, {style: "currency", currency: "rub"})}</span>
                    <span className='old-price'>{basePrice.toLocaleString(i18n.language, {style: "currency", currency: "rub"})}</span>
                </div>
            </div>
        </div>
    )
}

const SliderControls: React.FC = () => {
    const swiper = useSwiper();

    const [isBeginning, setIsBeginning] = useState(swiper.isBeginning);
    const [isEnd, setIsEnd] = useState(swiper.isEnd);

    useEffect(() => {
        const onIndexChange = () => {
            setIsBeginning(swiper.isBeginning);
            setIsEnd(swiper.isEnd);
        }

        swiper.on("activeIndexChange", onIndexChange);

        return () => {
            swiper.off("activeIndexChange", onIndexChange);
        }

    }, [swiper]);

    return (
        <div className="slider-controls">
            <button
                className="slider-slide-btn"
                disabled={isBeginning}
                onClick={() => swiper.slidePrev()}
                children={
                    <img src="/img/arrow-left-white.svg" className="prev-btn" alt="<" /> 
                }
            />
            <button
                className="slider-slide-btn"
                disabled={isEnd}
                onClick={() => swiper.slideNext()}
                children={
                    <img src="/img/arrow-right-white.svg" className="next-btn" alt=">" /> 
                }
            />
        </div>
    )
}

const BundlePurchasePopup: React.FC<{
    bundleId: string | null
    closeCb: () => void
}> = ({bundleId, closeCb}) => {
    const { t } = useTranslation();
    const [promocode, setPromocode] = useState("");
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);

    function handleBuy(){
        setLoading(true);
        setError(false);
        axios({
            url: `/api/order/bundle/${bundleId}`,
            method: "post",
            data: {
                promocode: promocode
            }
        }).then(({data}) => {
            const payment_link = data.link;
            window.location.assign(payment_link);
        }).catch(err => {
            console.error(err);
            setLoading(false);
            setError(true);
        })
    }
    
    return (
        <div className='bundle-popup-wrapper'>
            <div className='bundle-popup'>
                <div className='close-wrapper'>
                    <div className='popup-title'>{t("bundle_purchase")}</div>
                    <div className='close' onClick={closeCb}>×</div>
                </div>
                <FormInput
                    value={promocode}
                    setValue={setPromocode}
                    label={t("form_label_promocode")}  
                />
                <button
                    className='btn-link'
                    onClick={handleBuy}
                    children={t("bundle_buy")}
                    disabled={loading}
                />
            </div>
        </div>
    )
}

const BundleSlider: React.FC<{}> = () => {
    const [bundles, setBundles] = useState<Bundle[]>([]);
    const [current, setCurrent] = useState<string | null>(null)
    const [loaded, setLoaded] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        axios({
            url: "/api/bundle/recommended",
            method: "get"
        }).then(({data}) => {
            setBundles(data);
            setLoaded(true);
        }).catch((err) => {
            console.error("err with bundles", err);
        })
    }, []);

    if(loaded && !bundles.length) return null;

    return (
        <section className="bundle-slider-wrapper">
            <div className="container">
                {
                    !loaded ?
                        <LoadingSpinner />
                        :
                        <>
                        <Swiper
                            className='bundle-slider'
                            slidesPerView={1}
                            spaceBetween={30}
                            pagination={{
                                clickable: true
                            }}
                            modules={[Pagination, Autoplay]}
                        >
                            {
                                bundles.map(bundle => (
                                    <SwiperSlide key={bundle._id}>
                                        <BundleItem
                                            onSelect={() => setCurrent(bundle._id)}
                                            {...bundle}
                                        />
                                    </SwiperSlide>
                                ))
                            }
                            <SliderControls />
                        </Swiper>
                        <p className='bundle-tip'>{t("bundle_tip")}</p>
                        {
                            current && (
                                <BundlePurchasePopup
                                    bundleId={current}
                                    closeCb={() => setCurrent(null)}
                                />
                            )
                        }
                        </>
                }
            </div>
        </section>
    )
}

export default BundleSlider;