import {Fragment} from "react"
import { Link } from "react-router-dom";

interface IPath {
    title: string
    link?: string
}

const Breadcrumb: React.FC<{path: IPath[]}> = ({path}) => {
    return (
        <div className="breadcrumb">
            {
                path.map((p, i) => (
                    p.link ? 
                        <Fragment key={"br"+p.title}>
                            <Link className="link" to={p.link}>{p.title}</Link>
                            <span className="separator">/</span>
                        </Fragment>
                        : 
                        <span key={"br"+p.title} className="link-inactive">{p.title}</span>
                ))
            }
        </div>
    )
}

export default Breadcrumb;