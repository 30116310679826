import { useTranslation } from "react-i18next"


const Feature: React.FC<{title: string, desc: string}> = ({title, desc}) => {
    return (
        <div className="feature">
            <div className="title">{title}</div>
            <div className="desc">{desc}</div>
        </div>
    )
}

const FeaturesBlock: React.FC = () => {
    const { t } = useTranslation();
    return (
        <section className="features-section">
            <div className="container">
                <Feature
                    title={t("feature_1_title")}
                    desc={t("feature_1_desc")}
                />
                <Feature
                    title={t("feature_2_title")}
                    desc={t("feature_2_desc")}
                />
                <Feature
                    title={t("feature_3_title")}
                    desc={t("feature_3_desc")}
                />
                <Feature
                    title={t("feature_4_title")}
                    desc={t("feature_4_desc")}
                />
            </div>
        </section>
    )
}

export default FeaturesBlock;