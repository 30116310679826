import { useTranslation } from "react-i18next"
import axios from "axios";
import { useCallback, useState } from "react";


export const Feedback: React.FC<{}> = () => {
    const { t } = useTranslation();
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [sent, setSent] = useState(false);

    const submitFeedback = useCallback((message: string) => {
        setLoading(true);
        axios({
            method: "post",
            url: "/api/support",
            data: {
                message: message
            }
        }).then(() => {
            setSent(true)
        }).catch(() => {
            alert("Произошла ошибка!")
        }).finally(() => {
            setLoading(false);
        })
    }, []);

    return (
        <section className="feedback">
            <div className="container">
                <div className="feedback-wrapper">
                    <h4 className="title">{t("feedback_heading")}</h4>
                    <p className="description">{t("feedback_text")}</p>
                    <textarea
                        className="feedback-text"
                        disabled={loading || sent}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                    />
                    <div className="submit-wrapper">
                        <button
                            className="btn-secondary"
                            disabled={!message.length || loading || sent}
                            onClick={() => submitFeedback(message)}
                            children={sent ? t("feedback_sent") : loading ? t("feedback_sending") : t("feedback_send_button")}
                        />
                        <p className="policy">{t("feedback_consent")}</p>
                    </div>
                </div>
            </div>
        </section>
    )
}