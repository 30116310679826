import { useTranslation } from "react-i18next";

const Jumbo: React.FC<{}> = () => {
    const { t } = useTranslation();

    return (
        <section className="jumbo-section">
            <div className="container">
                <div className="notice-block">
                    {t("old_acc_notice")}
                </div>
                <div className="jumbo">
                    <p className="text-wrapper">
                        {t("home_slogan")}
                    </p>
                </div>
            </div>
        </section>
    )
}

export default Jumbo;