

const LoadingSpinner: React.FC<{}> = () => {
    return (
        <div className="spinner-wrapper">
            <div className="loading-spinner" />
        </div>
    )
}

export default LoadingSpinner;