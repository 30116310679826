import Header from "../../components/Header";
import Breadcrumb from "../../components/Breadcrumb";
import { MoreProducts } from "../../components/Products";
import Social from "../../components/Social";

const path = [
    {
        title: "Главная",
        link: "/"
    },
    {
        title: "Политики и оферта",
        link: "/policy"
    },
    {
        title: "Реквизиты организации"
    }
]

const Doc5Page: React.FC<{}> = () => {
    return (
        <div className="page-wrapper">
            <Header />
            <div className="container light-bg">
                <Breadcrumb path={path} />
                <div className="title-wrapper">
                    <h2 className="title">Реквизиты организации</h2>
                </div>
                <div className="doc">
                    <h1>Реквизиты организации</h1>
                    <h4>Наименование компании</h4>
                    <p>Полное наименование	ИП Хворостин И.А.</p>
                    <p>Сокращенное (на иностранном языке)	IP Khvorostin I.A.</p>
                    <h4>Регистрационная информация</h4>
                    <p>Юридический адрес:	Москва, Зеленоград</p>
                    <p>Фактический адрес:	Москва, Зеленоград</p>
                    <p>ИНН:	773573717069</p>
                    <p>Банк получателя:	ПАО Сбербанк</p>
                    <p>Расчетный счет:	40802810140020011331</p>
                    <p>Корреспондентский счет:	30101810400000000225</p>
                    <p>БИК:	044525225</p>
                    <p>ОГРН:	312774633300557</p>
                </div>
            </div>
            <MoreProducts />
            <Social light />
        </div>
    )
}

export default Doc5Page;