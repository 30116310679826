import { useContext, useEffect, useState } from "react";
import Header from "../components/Header";
import Social from "../components/Social";
import { useTranslation } from "react-i18next";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { AuthDispatchContext, IUser } from "../context/AuthContext";
import LoadingSpinner from "../components/LoadingSpinner";

const EmailVerificationSubmitPage: React.FC<{}> = () => {
    const dispatch = useContext(AuthDispatchContext);
    const navigate = useNavigate();
    const { token } = useParams();
    
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        console.log("TOKEN", token);
        axios({
            url: "/api/user/submit-email-verification",
            method: "post",
            data: {
                token: token
            }
        }).then(({data}) => {
            setSuccess(true);
            dispatch({
                type: "login",
                user: data as IUser
            });
        }).catch(err => {
            console.log("err_verification_email", err);
            setSuccess(false);
            navigate("/login", {
                replace: true
            })
        }).finally(() => {
            setLoading(false);
        })
    }, [token]);

    const { t } = useTranslation();
    return (
        <div className="page-wrapper">
            <Header />
            <div className="light-bg full-page-block">
                {
                    loading
                        ? <LoadingSpinner />
                        : <Navigate to="/dashboard" replace />
                        
                }
            </div>
            <Social />
        </div>
    )
}

export default EmailVerificationSubmitPage;