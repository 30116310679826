
const Preloader: React.FC<{}> = () => {
    return (
        <div className="site-preloader">
            <div className="lds-roller">
                <div /><div /><div /><div /><div /><div /><div /><div />
            </div>
        </div>
    )
}

export default Preloader;