import { Link } from "react-router-dom";

import Header from "../components/Header";
import Breadcrumb from "../components/Breadcrumb";
import Social from "../components/Social";
import { MoreProducts } from "../components/Products";

const path = [
    {
        title: "Главная",
        link: "/"
    },
    {
        title: "Политики и оферта"
    }
]

const policyLinks = [
    {
        title: "Публичная оферта",
        link: "/policy/doc1"
    },
    {
        title: "Соглашение по использованию сервиса",
        link: "/policy/doc2"
    },
    {
        title: "Положение по обработке персональных данных",
        link: "/policy/doc3"
    },
    {
        title: "Информация о доставке",
        link: "/policy/doc4"
    },
    {
        title: "Реквизиты организации",
        link: "/policy/doc5"
    },
]

interface IPolicyLink {
    title: string
    link: string
}

const PolicyLink: React.FC<IPolicyLink> = ({title, link}) => {
    return <Link to={link} className="policy-link">{title}</Link>
}

const PolicyPage: React.FC<{}> = () => {
    return (
        <div className="page-wrapper">
            <Header />
            <div className="container light-bg">
                <Breadcrumb path={path} />
                <div className="title-wrapper">
                    <h2 className="title">Политики и оферта</h2>
                </div>
                <div className="policy-links">
                    {
                        policyLinks.map(link => <PolicyLink key={link.link} {...link} />)
                    }
                </div>
            </div>
            <MoreProducts />
            <Social light />
        </div>
    )
}

export default PolicyPage;