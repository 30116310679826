import { Swiper, SwiperSlide } from 'swiper/react';
import { useEffect, useState } from "react";
import { Pagination } from "swiper";
import useMobile from "../hooks/useMobile";
import axios from "axios";

import 'swiper/css';
import "swiper/css/pagination";

import LoadingSpinner from './LoadingSpinner';
import { useTranslation } from 'react-i18next';
import { CDN_BASE } from '..';

export type IReview = {
    author: string
    text: string
    file: string | null
    _id: string
}

const Review: React.FC<IReview> = ({author, text, file}) => {
    const { t } = useTranslation();
    const ext = file ? file.split(".")[1] : null;
    return (
        <SwiperSlide className="review-wrapper">
            <p className='review-author'>{author} {t("review_says")}:</p>
            {
                file ?
                    ext === 'mp4' ?
                        <video className='review-video' controls>
                            <source src={`${CDN_BASE}/${file}`} />
                        </video>
                        :
                        <img className='review-image' src={`${CDN_BASE}/${file}`} alt={author} />
                    :
                    <p className='review-text'>{text}</p>
            }
        </SwiperSlide>
    )
}

const Reviews: React.FC<{}> = () => {
    const [reviews, setReviews] = useState<IReview[]>([]);
    const [loaded, setLoaded] = useState(false);
    const [isMobile] = useMobile(800);
    const [isTablet] = useMobile(1200);
    const { t } = useTranslation();

    useEffect(() => {
        axios.get("/api/review")
            .then((response: {data: {reviews: IReview[]}}) => {
                setLoaded(true);
                setReviews(response.data.reviews);
            })
    }, [])

    if(loaded && !reviews.length) return null;

    return (
        <section className="reviews-section">
            <div className="container">
                <h4 className='heading-2'>{t("reviews_heading")}</h4>
                {
                    !loaded ?
                        <LoadingSpinner />
                        :
                        <Swiper
                            className='reviews-slider'
                            spaceBetween={30}
                            slidesPerView={isMobile ? 1 : isTablet ? 2 : 3}
                            pagination={true}
                            modules={[Pagination]}
                        >
                            {
                                reviews.map(review => (
                                    <SwiperSlide key={review._id}>
                                        <Review {...review} />
                                    </SwiperSlide>
                                ))
                            }
                        </Swiper>
                }
            </div>
        </section>
    )
}

export default Reviews;