import Header from "../components/Header";
import Reviews from "../components/Reviews";
import Jumbo from "../components/Jumbo";
import ProjectTiles from "../components/ProjectTiles";
import Social from "../components/Social";
import FeaturesBlock from "../components/Features";

export const HomePage: React.FC<{}> = () => {
    return (
        <div className="page-wrapper">
            <Header />
            <div className="light_bg">
                <Jumbo />
                <ProjectTiles />
            </div>
            <FeaturesBlock />
            <Reviews />
            <Social />
        </div>
    )
}

export default HomePage;