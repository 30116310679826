import axios from "axios";
import {useState, useContext} from "react";
import { Link } from "react-router-dom"
import { useNavigate } from "react-router-dom";
import { AuthDispatchContext } from "../context/AuthContext";

import Header from "../components/Header";
import Social from "../components/Social";
import { useTranslation } from "react-i18next";
import FormInput from "../components/FormInput";

const SignupForm: React.FC<{}> = () => {
    const { t } = useTranslation();
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [passwordRepeat, setPasswordRepeat] = useState("");

    const dispatch = useContext(AuthDispatchContext);
    const navigate = useNavigate();

    function Signup(){
        setError(null);
        if(password !== passwordRepeat) return setError(t("form_error_password_mismatch"));
        setLoading(true);
        axios.post("/api/auth/signup", {
            email, password
        }, {
            withCredentials: true
        }).then(({data}: any) => {
            dispatch({type: "login", user: data.user});
            navigate("/dashboard")
        }).catch((error: any) => {
            setError(t("form_error_signup_failed"))
        }).finally(() => {
            setLoading(false);
        })
    }

    return (
        <div className="auth-form Signup-form form">
            <div className="form-title">{t("signup_form_title")}</div>
            <div className="tip">{t("signup-tip")}</div>
            {error && <div className="auth-error">{error}</div>}
            <FormInput
                label={t("form_label_email")}
                value={email}
                setValue={setEmail}
            />
            <FormInput
                label={t("form_label_password")}
                value={password}
                setValue={setPassword}
            />
            <FormInput
                label={t("form_label_password_repeat")}
                value={passwordRepeat}
                setValue={setPasswordRepeat}
            />
            <button disabled={loading} className="auth-submit" onClick={Signup}>{t("nav_signup")}</button>
            <div className="auth-links">
                <Link className="auth-link" to="/login">{t("nav_login")}</Link>
                <Link className="auth-link" to="/reset-password">{t("nav_reset")}</Link>
            </div>
        </div>
    )
}

const SignupPage: React.FC<{}> = () => {
        return (
            <div className="page-wrapper">
                <Header />
                <div className="light-bg full-page-block">
                    <SignupForm />
                </div>
                <Social />
            </div>
        )
}

export default SignupPage;