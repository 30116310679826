import axios from "axios";
import {useState, useContext, useEffect} from "react";
import { Link } from "react-router-dom"
import { AuthDispatchContext } from "../context/AuthContext";
import { useNavigate } from "react-router";

import Header from "../components/Header";
import Social from "../components/Social";
import { useTranslation } from "react-i18next";
import { ProductDispatchContext } from "../context/ProductContext";
import ym from "react-yandex-metrika";

const SuccessPage: React.FC<{}> = () => {
    const cartDispatch = useContext(ProductDispatchContext);
    const { t } = useTranslation();

    useEffect(() => {
        cartDispatch({type: "CART_CLEAR"});
    }, []);

    return (
        <div className="page-wrapper">
            <Header />
            <div className="light-bg full-page-block">
                <div className="success-page status-page">
                    <img src="/img/receipt.svg" className="icon" />
                    <div className="text">{t("payment_success")}</div>
                    <Link className="btn-link" to="/dashboard" onClick={() => ym('hit', 'success-page')}>{t("goto_courses")}</Link>
                </div>
            </div>
            <Social />
        </div>
    )
}

export default SuccessPage;