import { Link } from "react-router-dom";

import Breadcrumb from "../components/Breadcrumb";
import Header from "../components/Header";
import Social from "../components/Social";
import { Feedback } from "../components/Feedback";
import { DashboardProduct } from "../components/Products";

import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { useTranslation } from "react-i18next";
import ym from "react-yandex-metrika";

const NoProductsPlaceholder: React.FC = () => {
    const { t } = useTranslation();

    return (
        <div className="no-products-placeholder">
            <img className="icon" src="/icon/face-sad-cry.svg" alt="" />
            <span className="label">{t("products_empty_label")}</span>
        </div>
    )
}

const DashboardPage: React.FC<{}> = () => {
    const { t } = useTranslation()
    const { user } = useContext(AuthContext);

    const path = [
        {
            title: t("nav_home"),
            link: "/"
        },
        {
            title: t("nav_dashboard")
        }
    ]

    return (
        <div className="page-wrapper dashboard">
            <Header />
            <div className="container dashboard-container">
                <Breadcrumb path={path} />
                <div className="title-wrapper">
                    <h2 className="title">{t("dashboard_heading")}</h2>
                </div>
                <div className="dashboard-products">
                    {
                        user?.products.length ?
                            user?.products.map(product => <DashboardProduct key={product._id} {...product} />) :
                            <NoProductsPlaceholder />
                    }
                </div>
                <div className="catalog-link-wrapper">
                    <Link className="btn-link" to="/catalog"  onClick={() => ym('hit', `navigation-catalog`)}>{t("goto_catalog_cta")}</Link>
                </div>
            </div>
            <Social />
        </div>
    )
}

export default DashboardPage;