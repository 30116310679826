import Header from "../../components/Header";
import Breadcrumb from "../../components/Breadcrumb";
import { MoreProducts } from "../../components/Products";
import Social from "../../components/Social";

const path = [
    {
        title: "Главная",
        link: "/"
    },
    {
        title: "Политики и оферта",
        link: "/policy"
    },
    {
        title: "Положение"
    }
]

const Doc3Page: React.FC<{}> = () => {
    return (
        <div className="page-wrapper">
            <Header />
            <div className="container light-bg">
                <Breadcrumb path={path} />
                <div className="title-wrapper">
                    <h2 className="title">Положение по обработке персональных данных</h2>
                </div>
                <div className="doc">
                    <h4>Индивидуальный Предприниматель Хворостин И.А.</h4>
                    <p>1.1. Настоящее Положение по обработке персональных данных (далее – Положение) разработано и применяется в ИП «Хворостин И.А.» (далее –Оператор) в соответствии с пп. 2 ч. 1 ст. 18.1 Федерального закона от 27.07.2006 No 152-ФЗ «О персональных данных» (далее по тексту – Федеральный закон «О персональных данных»).</p>
                    <p>1.2. Настоящее Положение определяет политику Оператора в отношении обработки персональных данных, принятых на обработку, порядок и условия осуществления обработки персональных данных физических лиц, передавших свои персональные данные для обработки Оператору (далее – субъекты персональных данных) с использованием и без использования средств автоматизации, устанавливает процедуры, направленные на предотвращение нарушений законодательства Российской Федерации, устранение последствий таких нарушений, связанных с обработкой персональных данных.</p>
                    <p>1.3. Положение разработано с целью обеспечения защиты прав и свобод субъектов персональных данных при обработке их персональных данных, а также с целью установления ответственности должностных лиц Оператора, имеющих доступ к персональным данным субъектов персональных данных, за невыполнение требований и норм, регулирующих обработку персональных данных.</p>
                    <p>1.4. Действие настоящего Положения не распространяется на отношения:</p>
                    <p>возникающие при обработке персональных данных сотрудников Оператора, поскольку такие отношения урегулированы отдельным локальным актом;на отношения, на которые Закон о персональных данных не распространяется (п.2 ст.1 Закона).</p>
                    <p>1.5. Персональные данные Субъекта персональных данных – это любая информация, относящаяся к прямо или косвенно определенному или определяемому физическому лицу.</p>
                    <p>1.7. Оператор осуществляет обработку следующих персональных данных:</p>
                    <p>- Фамилия, Имя, Отчество;</p>
                    <p>- Адрес электронной почты;</p>
                    <p>- Номер телефона;</p>
                    <p>- Дата рождения;</p>
                    <p>- Данные аккаунтов социальных сетей;</p>
                    <p>- Сведения об используемом браузере;</p>
                    <p>- Местоположение;</p>
                    <p>- IP-адрес;</p>
                    <p>- Данные файлов cookie;</p>
                    <p>- Запрашиваемые Интернет-страницы;</p>
                    <p>- Адрес доставки продукции;</p>
                    <p>При получении персональных данных, не указанных в настоящем пункте, такие данные подлежат немедленному уничтожению лицом, непреднамеренно получившим их.</p>
                    <p>1.8. Оператор осуществляет обработку персональных данных Субъектов персональных данных в следующих целях:</p>
                    <p>-обработки заявок Субъекта персональных данных и выполнения перед ним своих обязательств;</p>
                    <p>-информирования Субъекта персональных данных об акциях, специальных предложениях, о новых товарах и услугах;</p>
                    <p>-в иных целях в случае, если соответствующие действия Оператора не противоречат действующему законодательству, деятельности Оператора, и на проведение указанной обработки получено согласие Субъекта персональных данных;</p>
                    <p>1.9. Оператор осуществляет обработку персональных данных посетителей Сайта посредством совершения любого действия (операции) или совокупности действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств, включая следующие:</p>
                    <p>сбор;</p>
                    <p>запись;</p>
                    <p>систематизацию;</p>
                    <p>накопление;</p>
                    <p>хранение;</p>
                    <p>уточнение (обновление, изменение); извлечение;</p>
                    <p>использование;</p>
                    <p>передачу (распространение, предоставление, доступ); обезличивание;</p>
                    <p>блокирование;</p>
                    <p>удаление;</p>
                    <p>уничтожение.</p>
                    <p>2. ПРИНЦИПЫ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ.</p>
                    <p>2.1. При обработке персональных данных Оператор руководствуется следующими принципами:</p>
                    <p>законности и справедливости;</p>
                    <p>своевременности и достоверности получения согласия субъекта персональных данных на обработку персональных данных;</p>
                    <p>обработки только персональных данных, которые отвечают целям их обработки; соответствия содержания и объема обрабатываемых персональных данных заявленным целям обработки. Обрабатываемые персональные данные не должны быть избыточными по отношению к заявленным целям их обработки;</p>
                    <p>недопустимости объединения баз данных, содержащих персональные данные, обработка которых осуществляется в целях, несовместимых между собой; обеспечения точности персональных данных, их достаточности, а в необходимых случаях и актуальности по отношению к целям обработки персональных данных. Оператор принимает необходимые меры либо обеспечивает их принятие по удалению или уточнению неполных или неточных данных;</p>
                    <p>хранения персональных данных в форме, позволяющей определить субъекта персональных данных, не дольше, чем этого требуют цели обработки персональных данных. </p>
                    <p>обеспечения точности, достаточности и актуальности персональных данных по отношению к целям обработки персональных данных;</p>
                    <p>уничтожения либо обезличивания персональных данных по достижению целей, их обработки или в случае утраты необходимости в достижении этих целей.</p>
                    <p>2.2. Обработка персональных данных Оператором осуществляется с соблюдением принципов и правил, предусмотренных:</p>
                    <p> Федеральным законом от 27.07.2006 No152-ФЗ «О персональных данных»;</p>
                    <p> Настоящим Положением; ст.12 Всеобщей Декларацией прав человека 1948 года;</p>
                    <p>ст.17 Международного пакта о гражданских и политических правах 1966 года;</p>
                    <p>ст.8 Европейской конвенци и о защите прав человека и основных свобод 1950 года; Положениями Конвенци и Содружества Независимых Государств о правах и основных свободах человека (Минск, 1995 г.), ратифицированной РФ 11.08. 1998 года; Положениями Окинавской Хартии глобального информационного общества, принятой 22.07.2000 года;</p>
                    <p>2.3. Обработка персональных данных Оператором осуществляется в соответствии с:</p>
                    <p>Постановлением Правительства РФ от 01.11.2012г. No 1119 «Об утверждении требований к защите персональных данных при их обработке в информационных системах персональных данных»;Приказом ФСТЭК России от 18.02.2013г. No 21 «Об утверждении Состава и содержания организационных и технических мер по обеспечению безопасности персональных данных при их обработке в информационных системах персональных данных»;Положением о методах и способах защиты информации в информационных системах персональных данных, утверждено Приказом ФСТЭК России от 5 февраля 2010 г. N 58, регистрационный N 16456;Иными нормативными и ненормативными правовыми актами, регулирующими вопросы обработки персональных данных.</p>
                    <p>3. ПОЛУЧЕНИЕ ПЕРСОНАЛЬНЫХ ДАННЫХ.</p>
                    <p>3.1. Персональные данные субъектов персональных данных получаются Оператором: путем личной передачи субъектом персональных данных при внесении данных на сайт по адресу: https://viksi.me</p>
                    <p>иными способами, не противоречащими законодательству РФ и требованиям международного законодательства о защите персональных данных.</p>
                    <p>3.2. Оператор получает и начинает обработку персональных данных Субъекта с момента получения его согласия.</p>
                    <p>Согласие на обработку персональных данных может быть дано Субъектом персональных данных в любой форме, позволяющей подтвердить факт получения согласия, если иное не установлено федеральным законом: в письменной, устной или иной форме, предусмотренной действующим законодательством, в том числе посредством совершения субъектом персональных данных конклюдентных действий. 3.3. Согласие на обработку персональных данных считается предоставленным Субъектом персональных данных посредством совершения Субъектом персональных данных следующих конклюдентных действий в совокупности:</p>
                    <p>-проставление в соответствующей форме отметки о согласии на обработку персональных данных в объеме, для целей и в порядке, предусмотренных в предлагаемом перед отправлением формы заявки для ознакомления тексте (текст согласия - Приложение No 1 к настоящему Положению).</p>
                    <p>Согласие считается полученным с момента подтверждения Субъектом персональных данных в установленном порядке и действует до момента направления Субъектом персональных данных соответствующего заявления о прекращении обработки персональных данных по месту нахождения Оператора.</p>
                    <p>В случае отсутствия согласия Субъекта персональных данных на обработку его персональных данных, такая обработка не осуществляется.</p>
                    <p>3.4. Получение Оператором персональных данных от иных лиц, а равно передача поручения по обработке персональных данных осуществляется на основании договора, содержащего условия о порядке обработки и сохранения конфиденциальности полученных персональных данных.</p>
                    <p>3.5. Субъект персональных данных может в любой момент отозвать свое согласие на обработку персональных данных при условии, что подобная процедура не нарушает требований законодательства РФ. В случае отзыва Субъектом персональных данных согласия на обработку персональных данных, Оператор вправе продолжить обработку персональных данных без согласия Субъекта персональных данных только при наличии оснований, указанных в Федеральном законе «О персональных данных».</p>
                    <p>3.6. Порядок отзыва согласия на обработку персональных данных:</p>
                    <p>- для отзыва согласия на обработку персональных данных, данного в письменной форме, необходимо подать соответствующее заявление в письменной форме по месту нахождения Оператора;</p>
                    <p>3.7. В случае отзыва Субъектом персональных данных согласия на обработку его персональных данных, Оператор должен прекратить их обработку или обеспечить прекращение такой обработки (если обработка осуществляется другим лицом, действующим по поручению Оператора) и в случае, если сохранение персональных данных более не требуется для целей их обработки, уничтожить персональные данные или обеспечить их уничтожение (если обработка персональных данных осуществляется другим лицом, действующим по поручению Оператора) в срок, не превышающий 30 (Тридцати) дней с даты поступления указанного отзыва, если иное не предусмотрено договором, стороной которого, выгодоприобретателем или поручителем по которому является Субъект персональных данных, иным соглашением между Оператором и Субъектом персональных данных, либо если Оператор не вправе осуществлять обработку персональных данных без согласия Субъекта персональных данных на основаниях, предусмотренных Федеральным законом «О персональных данных» или другими федеральными законами.</p>
                    <p>4. ПРАВИЛА И ПОРЯДОК ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ.</p>
                    <p>4.1. Оператор до начала обработки персональных данных назначает ответственного за организацию обработки персональных данных в должности не ниже начальника структурного подразделения, именуемого далее «Куратор ОПД».</p>
                    <p>4.1.1. Куратор ОПД получает указания непосредственно от исполнительного органа Оператора и подотчетен ему.</p>
                    <p>4.1.2. Куратор вправе оформлять и подписывать уведомление в уполномоченный орган по защите прав субъектов персональных данных о намерении Оператора осуществлять обработку персональных данных.</p>
                    <p>4.2. Обработку персональных данных осуществляют сотрудники Оператора, уполномоченные на то Регламентом управления доступом к персональным данным работников ИП Хворостин И.А., иными внутренними документами Оператора. Сотрудники Оператора, непосредственно осуществляющие обработку персональных данных, должны быть ознакомлены до начала работы:</p>
                    <p>с положениями законодательства Российской Федерации о персональных данных, в том числе с требованиями к порядку защиты персональных данных;</p>
                    <p>с документами, определяющими политику Оператора в отношении обработки персональных данных, в том числе с настоящим Положением, приложениями и изменениями к нему;</p>
                    <p>с локальными актами по вопросам обработки персональных данных.</p>
                    <p>Обучение работников организуется в соответствии с утвержденными Оператором графиками.</p>
                    <p>Сотрудники Оператора имеют право получать только те персональные данные, которые необходимы им для выполнения конкретных должностных обязанностей. Сотрудники Оператора, осуществляющие обработку персональных данных, должны быть проинформированы о факте такой обработки, об особенностях и правилах такой обработки, установленных нормативно-правовыми актами и внутренними документами Оператора.</p>
                    <p>4.3. При обработке персональных данных Оператор применяет правовые, организационные и технические меры по обеспечению безопасности персональных данных в соответствии со ст. 19 Федерального закона «О персональных данных», Положением об обеспечении безопасности персональных данных при их обработке в информационных системах персональных данных, утвержденным Постановлением Правительства РФ от 17.11.2007 г. No 781, Методикой определения актуальных угроз безопасности персональных данных при их обработке в информационных системах персональных данных, утвержденной ФСТЭК РФ 14.02.2008 г., Рекомендациями по заполнению образца формы уведомления об обработке (о намерении осуществлять обработку) персональных данных, утвержденными Приказом Роскомнадзора от 19.08.2011 г. No 706, Методическими рекомендациями по обеспечению с помощью криптосредств безопасности персональных данных при их обработке в информационных системах персональных данных с использованием средств автоматизации, утвержденных ФСБ РФ 21.02.2008 г. No 149/54-144.</p>
                    <p>4.4. Режим конфиденциальности персональных данных Оператор обеспечивает в соответствии с Соглашением о конфиденциальности.</p>
                    <p>4.5. Контроль за соблюдением сотрудниками Оператора требований законодательства РФ и норм международного законодательства, а также положений локальных нормативных актов Оператора организован Оператором в соответствии с Политикой об обработке персональных данных.</p>
                    <p>4.6. Оценка вреда, который может быть причинен Субъектам персональных данных в случае нарушения Оператором требований Закона об обработке персональных данных, определяется в соответствии со ст. ст. 15, 151, 152, 1101 Гражданского кодекса РФ. 4.7. Опубликование или обеспечение иным образом неограниченного доступа к настоящей Политике, иным документам, определяющим политику Оператора в отношении обработки персональных данных, к сведениям о реализуемых требованиях к защите персональных данных Оператор осуществляет посредством размещения на электронном сайте, принадлежащем Оператору. Доступ к персональным данным Субъектов имеют сотрудники Оператора, которым персональные данные необходимы в связи с исполнением ими трудовых обязанностей. Перечень сотрудников, имеющих доступ к персональным данным, устанавливается локальным нормативным актом Оператора.</p>
                    <p>Доступ к персональным данным Субъектов сотрудников, не включенных в вышеуказанный перечень, осуществляется на основании приказа Исполнительного директора Оператора, подготовленного на основании служебной записки руководителя сотрудника с положительной резолюцией куратора ОПД.</p>
                    <p>В случае если Оператор поручает обработку персональных данных третьим лицам, не являющимся его сотрудниками, на основании заключенных договоров (либо иных оснований), в силу которых они должны иметь доступ к персональным данным пользователей Сайта, соответствующие данные предоставляются Оператором только после подписания с лицами, осуществляющими обработку персональных данных по поручению Оператора, соответствующего соглашения, в котором должны быть определены перечень действий (операций) с персональными данными, которые будут совершаться лицом, осуществляющим их обработку, и цели обработки, должна быть установлена обязанность такого лица соблюдать конфиденциальность персональных данных и обеспечивать безопасность персональных данных при их обработке, а также должны быть указаны требования к защите обрабатываемых персональных данных в соответствии со статьей 19 Федерального закона «О персональных данных».</p>
                    <p>5.СТРУКТУРНЫЕ ПОДРАЗДЕЛЕНИЯ ОПЕРАТОРА ПО ОБРАБОТКЕ ПЕРСОНАЛЬНЫХ ДАННЫХ.</p>
                    <p>5.1. Обработку персональных данных организует Информационно-технический отдел (далее – «Служба ОПД»).</p>
                    <p>5.2. Служба ОПД находится в непосредственном подчинении куратора ОПД.</p>
                    <p>5.3. Состав, уровень квалификации сотрудников, полномочия, функции, условия допуска сотрудников к персональным данным, порядок взаимодействия с другими структурными подразделениями оператора, ответственность Службы ОПД установлены локальными актами оператора.</p>
                    <p>5.4. Служба ОПД под руководством куратора ОПД:</p>
                    <p>1) доводит до сведения работников Оператора положения законодательства Российской Федерации о персональных данных, локальных актов по вопросам обработки персональных данных, требований к защите персональных данных;</p>
                    <p>2) организует обработку персональных данных сотрудниками оператора;</p>
                    <p>3) организует прием и обработку обращений и запросов субъектов персональных данных или их представителей.</p>
                    <p>5.5. Контроль исполнения сотрудниками Оператора требований законодательства РФ и положений локальных нормативных актов Оператора в сфере персональных данных осуществляет исполнительный орган Оператора.</p>
                    <p>6. ПОРЯДОК ОБЕСПЕЧЕНИЯ ОПЕРАТОРОМ ПРАВ СУБЪЕКТА ПЕРСОНАЛЬНЫХ ДАННЫХ.</p>
                    <p>6.1. Субъекты персональных данных или их представители обладают правами, предусмотренными Федеральным законом «О персональных данных» и другими</p>
                    <p>нормативно-правовыми актами, регламентирующими обработку персональных данных.</p>
                    <p>6.2. Оператор обеспечивает права Субъектов персональных данных в порядке, установленном Федеральным законом «О персональных данных».</p>
                    <p>6.3. Полномочия представителя на представление интересов каждого Субъекта персональных данных подтверждаются доверенностью, оформленной в установленном законодательством порядке. Копия доверенности представителя хранится Оператором не менее 3 (Трех) лет, а в случае, если срок хранения персональных данных больше трех лет, - не менее срока хранения персональных данных.</p>
                    <p>6.4. Сведения, указанные в ч. 7 ст. 14 Федерального закона «О персональных данных», предоставляются Субъекту персональных данных Службой ОПД в доступной форме без персональных данных, относящихся к другим Субъектам персональных данных, за исключением случаев, если имеются законные основания для раскрытия таких персональных данных в электронном виде. По требованию Субъекта персональных данных они могут быть продублированы на бумаге. Доступная форма заверяется Куратором ОПД или иным уполномоченным приказом руководителя Оператора сотрудником службы ОПД.</p>
                    <p>6.5. Сведения, указанные в ч. 7 ст. 14 Федерального закона «О персональных данных», предоставляются Субъекту персональных данных или его представителю при личном обращении либо при получении Оператором соответствующего запроса Субъекта персональных данных или его представителя. Запрос должен содержать номер основного документа, удостоверяющего личность Субъекта персональных данных или его представителя, сведения о дате выдачи указанного документа и выдавшем его органе, сведения, подтверждающие участие Субъекта персональных данных в отношениях с Оператором (номер договора, дата заключения договора, условное словесное обозначение и (или) иные сведения), либо сведения, иным образом подтверждающие факт обработки персональных данных Оператором, подпись Субъекта персональных данных или его представителя. При наличии технической возможности запрос может быть направлен в форме электронного документа и подписан электронной подписью в соответствии с законодательством Российской Федерации.</p>
                    <p>6.6. Право Субъекта персональных данных на доступ к его персональным данным может быть ограничено в соответствии с федеральными законами, в том числе на основании ч. 8 ст. 14 Федерального закона «О персональных данных».</p>
                    <p>6.7. Оператор обязан немедленно прекратить по требованию Субъекта персональных данных обработку его персональных данных, осуществляемую на основании ч. 1 ст. 15 Федерального закона «О персональных данных».</p>
                    <p>6.8. Решение, влекущее юридические последствия в отношении Субъекта персональных данных или иным образом затрагивающее его права и законные интересы, может быть принято на основании исключительно автоматизированной обработки его персональных данных только при наличии согласия в письменной форме Субъекта персональных данных или в случаях, предусмотренных федеральными законами, устанавливающими также меры по обеспечению соблюдения прав и законных интересов Субъекта персональных данных.</p>
                    <p>6.9. Оператор обязан разъяснить Субъекту персональных данных порядок принятия решения на основании исключительно автоматизированной обработки его персональных данных и возможные юридические последствия такого решения, предоставить возможность заявить возражение против такого решения, а также разъяснить порядок защиты Субъектом персональных данных своих прав и законных интересов.</p>
                    <p>6.10.1. Текст устного разъяснения Оператор составляет в письменном виде до начала автоматизированной обработки персональных данных и хранит не менее 2 (двух) лет. 6.10.2. В случае автоматизированной обработки персональных данных различными способами указанное разъяснение готовится отдельно для каждого способа.</p>
                    <p>6.11. Оператор обязан рассмотреть возражение относительно решения, вынесенного на основании исключительно автоматизированной обработки персональных данных, в течение 30 (Тридцати) дней со дня получения возражения.</p>
                    <p>Оператор уведомляет Субъекта персональных данных о результатах рассмотрения возражения в течение 10 (Десяти) дней. Уведомление может быть осуществлено Оператором в любой доступной форме, позволяющей подтвердить факт уведомления Субъекта персональных данных (посредством электронной почты, посредством web- ресурса, предоставляющего субъекту персональных данных личное web-пространство на Сайте или на интернет-сайтах компаний-партнеров, телеграфным сообщением с уведомлением о вручении или почтовым сообщением с уведомлением о вручении). Выбор способа направления уведомлений остается за Оператором.</p>
                    <p>6.12. Оператор обязан предоставить безвозмездно Субъекту персональных данных или его представителю возможность ознакомления с персональными данными, относящимися к этому Субъекту персональных данных, по месту своего расположения в рабочее время.</p>
                    <p>6.13. Оператор в течение 5 дней с момента исправления или уничтожения персональных данных по требованию Субъекта персональных данных или его представителя обязан уведомить его о внесенных изменениях и предпринятых мерах и принять разумные меры для уведомления третьих лиц, которым персональные данные этого Субъекта были переданы. Уведомление может быть осуществлено Оператором в любой доступной форме, позволяющей подтвердить факт уведомления Субъекта персональных данных (посредством электронной почты, телеграфным сообщением с уведомлением о вручении или почтовым сообщением с уведомлением о вручении, иное). Выбор способа направления уведомления остается за Оператором.</p>
                    <p>6.14. Трансграничную передачу персональных данных Оператор не осуществляет.</p>
                    <p>7. ХРАНЕНИЕ ПЕРСОНАЛЬНЫХ ДАННЫХ.</p>
                    <p>7.1. Хранение персональных данных осуществляется в соответствии с письменным согласием Субъекта персональных данных и в течение срока, установленного с учетом требований действующего законодательства РФ.</p>
                    <p>В случае отсутствия в соответствующих нормативно-правовых актах сроков хранения отдельных видов персональных данных, указанные персональные данные подлежат</p>
                    <p>хранению в течение срока, указанного в письменном согласии соответствующего Субъекта персональных данных.</p>
                    <p>7.2. Хранение персональных данных осуществляется не дольше, чем этого требуют цели обработки персональных данных. Обрабатываемые персональные данные подлежат уничтожению либо обезличиванию по достижении целей обработки или в случае утраты необходимости в достижении этих целей (удаление аккаунта Субъекта персональных данных).</p>
                    <p>7.3. Хранение персональных данных, цели обработки которых различны, должно осуществляться раздельно в рамках информационной системы или, при условии хранения на материальных носителях, в рамках структуры дел соответствующего подразделения Оператора.</p>
                    <p>7.4. Сотрудник Оператора, имеющий доступ к персональным данным в связи с исполнением трудовых обязанностей обеспечивает хранение информации, содержащей персональные данные субъектов персональных данных, исключающее доступ к ним третьих лиц.</p>
                    <p>В отсутствие сотрудника на его рабочем месте не должно находиться документов, содержащих персональные данные. При уходе в отпуск, служебную командировку и иных случаях длительного отсутствия сотрудника на рабочем месте, он обязан передать документы и иные носители, содержащие персональные данные лицу, на которое локальным актом Оператора будет возложено исполнение его трудовых обязанностей. В случае если такое лицо не назначено, то документы и иные носители, содержащие персональные данные Субъектов персональных данных, передаются другому сотруднику, имеющему доступ к персональным данным Субъектов персональных данных по указанию руководителя соответствующего структурного подразделения Оператора.</p>
                    <p>При увольнении сотрудника, имеющего доступ к персональным данным, документы и иные носители, содержащие персональные данные Субъектов персональных данных, передаются другому сотруднику, имеющему доступ к персональным данным Субъектов персональных данных по указанию руководителя структурного подразделения и с уведомлением лица, ответственного за обработку персональных данных.</p>
                    <p>8. КОНТРОЛЬ, ОТВЕТСТВЕННОСТЬ ЗА НАРУШЕНИЕ ИЛИ НЕИСПОЛНЕНИЕ ПОЛОЖЕНИЯ.</p>
                    <p>8.1. Контроль исполнения настоящего Положения возложен на Куратора ОПД.</p>
                    <p>8.2. Лица, нарушающие или не исполняющие требования Положения, привлекаются к дисциплинарной, административной (ст. ст. 5.39, 13.11, 13.14 Кодекса об административных правонарушениях РФ) или уголовной ответственности (ст. ст. 137, 272 Уголовного кодекса РФ).</p>
                    <p>8.3. Руководители структурных подразделений оператора несут персональную ответственность за исполнение обязанностей их подчиненными.</p>
                    <p>9. ПРОЧИЕ ПОЛОЖЕНИЯ.</p>
                    <p>9.1. Настоящее Положение вступает в силу со дня его утверждения единоличным исполнительным органом Оператора.</p>
                    <p>9.2. Все сотрудники Оператора, допущенные к работе с персональными данными, должны быть ознакомлены с настоящим Положением до начала работы с персональными данными.</p>
                    <p>9.3. Копия настоящего Положения должна быть передана в Федеральную службу по надзору в сфере связи, информационных технологий и массовых коммуникаций.</p>
                    <p>Приложение No1</p>
                    <p>Согласие на обработку персональных данных</p>
                    <p>Согласие на обработку персональных данных</p>
                    <p>Настоящим я проинформирован о том, что в соответствии с Федеральным законом от 27.07.2006 N 152-ФЗ "О персональных данных", предоставленная мною информация, включая данные о:</p>
                    <p>-Фамилия, Имя, Отчество;</p>
                    <p>-Адрес электронной почты;</p>
                    <p>-Номер телефона;</p>
                    <p>-Дата рождения;</p>
                    <p>-Данные аккаунтов социальных сетей;</p>
                    <p>-Фотографии;</p>
                    <p>-Сведения об используемом браузере;</p>
                    <p>-Местоположение;</p>
                    <p>-IP-адрес;</p>
                    <p>-Данные файлов cookie;</p>
                    <p>-Запрашиваемые Интернет-страницы;</p>
                    <p>-Адрес доставки продукции;</p>
                    <p>будет внесена в архив Оператора по обработке персональных данных. Я предоставляю право обрабатывать эти данные с целью организации процесса предоставления запрошенных услуг, за исключением случаев, когда прямо установлено иное. Мои персональные данные будут использованы при записи на Тренинг на сайте viksi.me по адресу: https://viksi.me/ в целях, установленных п. 1.8. Положения по обработке персональных данных, а именно, в целях:</p>
                    <p>-обработки моих заявок и выполнения ИП Хворостин И.А. своих обязательств передо мной;</p>
                    <p>-информирования меня об акциях, специальных предложениях, о новых услугах ИП Хворостин И.А.;</p>
                    <p>В связи с указанными выше целями мои персональные данные могут быть сообщены третьим лицам.</p>
                    <p>В случае предоставления данных третьих лиц, включая контактных данных, я подтверждаю, что субъект персональных данных уведомлен об осуществлении обработки его персональных данных Оператором по обработке персональных данных. Настоящим я проинформирован о том, что Я вправе требовать уточнения моих персональных данных, их блокирования или уничтожения в случае, если персональные данные являются неполными, устаревшими, неточными или не являются необходимыми для заявленной цели обработки, Я также в любой момент могу потребовать прекращения обработки персональных данных, направив соответствующее заявление по адресу:</p>
                    <p>666viksi666@gmail.com</p>
                    <p>Я даю свое согласие на использование предоставленных персональных данных для направления коммерческой информации Оператором по обработке персональных данных и третьими лицами по указанному телефону и адресу электронной почты. Я предоставляю право отправлять мне информацию об услугах, предложениях и рекламных акциях Оператора и/или его Партнеров, в том числе с помощью электронных и мобильных средств связи.</p>
                    <p>Настоящим я проинформирован о том, что Я в любой момент могу отказаться от получения коммерческой информации, направив свое заявление по адресу: 666viksi666@gmail.com</p>
                    <p>Приложение N2</p>
                    <p>Соглашение о соблюдении конфиденциальности персональных данных</p>
                    <p>ИП Хворостин И.А.</p>
                    <p>СОГЛАШЕНИЕ о соблюдении конфиденциальности персональных данных</p>
                    <p>Настоящее Соглашение о конфиденциальности персональных данных (далее –</p>
                    <p> Соглашение) является официальным предложением (публичной офертой) ИП Хворостина И.А. , (далее – Оператор), заключить соглашение на условиях и в порядке, установленных настоящим Соглашением и действующим законодательством Российской Федерации, в адрес любого физического лица, обладающего дееспособностью и необходимыми полномочиями, по поводу нижеследующего:</p>
                    <p>1. Термины и определения, применяемые в настоящем Соглашении:</p>
                    <p> Персональные данные — любая информация, относящаяся к определенному или</p>
                    <p> определяемому на основании такой информации физическому лицу (субъекту</p>
                    <p> персональных данных), в том числе его фамилия, имя, отчество, адрес электронной</p>
                    <p> почты, номер телефона, дата рождения, данные аккаунтов социальных сетей и</p>
                    <p> сведения об используемом браузере, местоположение, IP-адрес, данные файлов cookie</p>
                    <p> и запрашиваемые Интернет-страницы, адрес доставки продукции;</p>
                    <p> Субъект персональных данных (Субъект) – любое дееспособное физическое лицо,</p>
                    <p> достигшее 18 лет, обладающее необходимыми полномочиями, предоставившее</p>
                    <p> персональные данные Оператору в целях их обработки;</p>
                    <p> Оператор - Общество с ограниченной ответственностью «Тренинг-центр»,</p>
                    <p> получающее Персональные данные или доступ к такой информации от Пользователя в</p>
                    <p> порядке и на условиях, предусмотренных настоящим Соглашением, и осуществляющее</p>
                    <p> обработку персональных данных Пользователя;</p>
                    <p> Аффилированное лицо (в отношении любой из Сторон) - любое физическое или</p>
                    <p> юридическое лицо, которое прямо или косвенно контролирует Сторону, находится под</p>
                    <p> контролем Стороны, либо находится со Стороной под общим контролем, а также</p>
                    <p> любое физическое или юридическое лицо, которое может быть признано</p>
                    <p> аффилированным лицом в соответствии с законодательством РФ. Под «контролем» для</p>
                    <p> целей настоящего Соглашения понимается возможность прямо или косвенно (в силу</p>
                    <p> преобладающего участия в уставном капитале, либо в соответствии с заключенным</p>
                    <p> между ними договором, либо участия в органах управления, либо иным образом)</p>
                    <p> определять решения, принимаемые подконтрольным лицом.</p>
                    <p> Сервис – Интернет-сервис Сайт VIKSI.ME, размещенный на странице</p>
                    <p> в сети Интернет по адресу https://viksi.me.</p>
                    <p> Разглашение персональных данных – несанкционированные Пользователем</p>
                    <p> действия Оператора, в результате которых третьи лица получают возможность</p>
                    <p> ознакомления с персональными данными. Распространением конфиденциальной</p>
                    <p> информации признается также бездействие Оператора, выразившееся в необеспечении</p>
                    <p> надлежащего уровня защиты полученных от Пользователя Персональных данных и</p>
                    <p> повлекшее получение доступа к такой информации со стороны третьих лиц.</p>
                    <p> Обезличенные персональные данные данные, на основании которых невозможно</p>
                    <p> определить их принадлежность конкретному субъекту персональных данных;</p>
                    <p> Общедоступные персональные данные персональные данные, размещенные в</p>
                    <p> общедоступных источниках с письменного согласия субъекта персональных данных</p>
                    <p> (общедоступные персональные данные могут быть в любое время исключены из</p>
                    <p> общедоступных источников персональных данных по требованию субъекта</p>
                    <p> персональных данных либо по решению суда или иных уполномоченных</p>
                    <p> государственных органов);</p>
                    <p> Информационная система персональных данных информационная система,</p>
                    <p> представляющая собой совокупность персональных данных, содержащихся в базе</p>
                    <p> данных, а также информационных технологий и технических средств, позволяющих</p>
                    <p> осуществлять обработку таких персональных данных с использованием средств</p>
                    <p> автоматизации или без использования таких средств;</p>
                    <p> Конфиденциальность персональных данных — обязательное для соблюдения</p>
                    <p> сторонами требование не допускать их распространение без согласия субъекта</p>
                    <p> персональных данных или наличия иного законного основания;</p>
                    <p> Обработка персональных данных — действия (операции) с персональными</p>
                    <p> данными, включая сбор, систематизацию, накопление, хранение, уточнение</p>
                    <p> (обновление, изменение), использование, распространение (в том числе передачу),</p>
                    <p> блокирование, уничтожение персональных данных;</p>
                    <p> Распространение персональных данных действия, направленные на передачу</p>
                    <p> персональных данных определенному кругу лиц (передача персональных данных) или</p>
                    <p> на ознакомление с персональными данными неограниченного круга лиц, в том числе</p>
                    <p> обнародование персональных данных в средствах массовой информации, размещение в</p>
                    <p> информационно-телекоммуникационных сетях или предоставление доступа к</p>
                    <p> персональным данным каким-либо иным способом;</p>
                    <p> Использование персональных данных действия (операции) с персональными</p>
                    <p> данными, совершаемые сторонами в целях принятия решений или совершения иных</p>
                    <p> действий, порождающих юридические последствия в отношении субъекта</p>
                    <p> персональных данных или других лиц либо иным образом затрагивающих права и</p>
                    <p> свободы субъекта персональных данных или других лиц;</p>
                    <p> Блокирование персональных данных временное прекращение сбора,</p>
                    <p> систематизации, накопления, использования, распространения персональных данных, в</p>
                    <p> том числе их передачи;</p>
                    <p> Уничтожение персональных данных действия, в результате которых невозможно</p>
                    <p> восстановить содержание персональных данных в информационной системе</p>
                    <p> персональных данных или в результате которых уничтожаются материальные</p>
                    <p> носители персональных данных.</p>
                    <p> Аккаунт - это совокупность данных, которая описывает субъекта персональных</p>
                    <p> данных в сети Интернет на сайте https://viksi.me.</p>
                    <p> 2. Оператор принимает на себя обязательство по обеспечению конфиденциальности и</p>
                    <p> безопасности персональных данных Субъекта в процессе их обработки в соответствии</p>
                    <p> с условиями настоящего Соглашения.</p>
                    <p> 2.1. Передача Персональных данных осуществляется Субъектом персональных данных</p>
                    <p> Оператору путем личной передачи. Оператор обеспечивает хранение информации,</p>
                    <p> которую Субъект указывает при регистрации в Сервисе на сайте https://viksi.me, а</p>
                    <p> также при оформлении заявок Оператором. Персональные данные используются при</p>
                    <p> создании пользовательского аккаунта в целях корректного и своевременного</p>
                    <p> выполнения услуг Оператором, а также с целью информирования о специальных</p>
                    <p> предложениях и новых возможностях Сервиса.</p>
                    <p> 2.2. Оператор с разрешения Субъекта персональных данных осуществляет обработку</p>
                    <p> его персональных данных, включая: сбор, запись, систематизацию, накопление,</p>
                    <p> хранение, уточнение (обновление, изменение), извлечение, использование,</p>
                    <p> распространение (в том числе передачу на территории Российской Федерации),</p>
                    <p> обезличивание, блокирование, удаление, уничтожение персональных данных, в том</p>
                    <p> числе запись, извлечение, использование, передачу (распространение, предоставление,</p>
                    <p> доступ).</p>
                    <p> 2.3. Оператор автоматически получает и фиксирует информацию из браузера Субъекта</p>
                    <p> или мобильной платформы, включая местоположение Субъекта, IP-адрес, данные</p>
                    <p> файлов cookie и запрашиваемые страницы.</p>
                    <p> 2.4. Личная информация в учетной записи Субъекта в Сервисе ______ защищена</p>
                    <p> паролем. Субъект обязан обеспечивать сохранность данных пароля самостоятельно.</p>
                    <p> Оператор не несет ответственность за утечку информации о персональных данных</p>
                    <p> Субъекта в связи с несанкционированным входом в личный кабинет Субъекта с</p>
                    <p> использованием его пароля.</p>
                    <p> 2.5. Оператор предоставляет Субъекту пользовательский доступ к следующей</p>
                    <p> информации с целью просмотра, обновления данной информации и проверки ее</p>
                    <p> корректности и полноты. Доступ к этой информации – в Личном кабинете на Сервисе.</p>
                    <p> Список может меняться по мере изменения Сервиса по усмотрению Оператора.</p>
                    <p> Пароль</p>
                    <p>Номер телефона</p>
                    <p>Адрес электронной почты</p>
                    <p>Прочая информация пользователя (дата рождения, город</p>
                    <p> местонахождения)</p>
                    <p>Удаление учетной записи</p>
                    <p> 2.6. Удалить учетную запись на Сервисе Субъект может самостоятельно на странице</p>
                    <p> настроек учетной записи. После удаления учетной записи профиль, включая историю</p>
                    <p> заказов, полученные бонусные баллы, всю предоставленную Субъектом информацию,</p>
                    <p> будет удалён с Сервиса, серверов и сайта viksi.me. Остаточные копии информации</p>
                    <p> профиля Субъекта могут оставаться на резервных носителях Оператора до 90 дней,</p>
                    <p> после чего подлежат обязательному удалению.</p>
                    <p> 2.7. Оператор по своему усмотрению и с учетом разумной необходимости вправе</p>
                    <p> передавать персональные данные Субъекта своим аффилированным лицам, а также</p>
                    <p> сотрудникам, которым такая информация необходима для работы в целях,</p>
                    <p> предусмотренных настоящим Соглашением.</p>
                    <p> 3. В целях исполнения настоящего Соглашения Оператор обязуется:</p>
                    <p> 3.1. Осуществлять обработку персональных данных Субъекта исключительно в</p>
                    <p> следующих целях:</p>
                    <p>соблюдения требований Конституции РФ, федеральных законов и иных</p>
                    <p> нормативно-правовых актов, внутренних актов ИП Хворостин И.А. по исполнению</p>
                    <p> прав и обязательств, появившихся в связи с предоставлением прав на использование</p>
                    <p> Субъектом Сервиса;</p>
                    <p>соблюдения требований Конституции РФ, федеральных законов и иных</p>
                    <p> нормативно-правовых актов, внутренних актов ИП Хворостин И.А. по исполнению</p>
                    <p> прав и обязательств, появившихся в связи с проведением конкурсов и акций</p>
                    <p> Оператором;</p>
                    <p>соблюдения требований Конституции РФ, федеральных законов и иных</p>
                    <p> нормативно-правовых актов, внутренних актов ИП Хворостин И.А. по исполнению</p>
                    <p> прав и обязательств, появившихся в связи с заключением в с субъектом гражданско-</p>
                    <p> правовых и иных договоров;</p>
                    <p>в целях проведения Оператором и аффилированными лицами маркетинговых акций,</p>
                    <p> рассылок;</p>
                    <p>в иных целях в случае, если соответствующие действия Оператора не противоречат</p>
                    <p> действующему законодательству, деятельности Оператора, и на проведение указанной</p>
                    <p> обработки получено согласие Субъекта персональных данных;</p>
                    <p> 3.2. Хранить персональные данные в форме, позволяющей определить субъекта</p>
                    <p> персональных данных, не дольше, чем этого требуют цели их обработки, уничтожать</p>
                    <p> персональные данные по достижении целей обработки, в случае утраты необходимости</p>
                    <p> в их достижении или в случае получения заявления Субъекта персональных данных об</p>
                    <p> отзыве согласия на обработку его персональных данных в порядке, установленном</p>
                    <p> п.3.7. Положения о персональных данных, утвержденных от 01.06.2017</p>
                    <p> 3.3. Обеспечивать конфиденциальность персональных данных, за исключением</p>
                    <p> обезличенных и общедоступных персональных данных;</p>
                    <p> 3.4. Принимать необходимые организационные и технические меры для защиты</p>
                    <p> персональных данных от неправомерного или случайного доступа к ним, уничтожения,</p>
                    <p> изменения, блокирования, копирования, распространения персональных данных, а</p>
                    <p> также от иных неправомерных действий;</p>
                    <p> 3.5. Выполнять установленные нормативно-правовыми актами Российской Федерации</p>
                    <p> требования к обеспечению безопасности персональных данных при их обработке в</p>
                    <p> информационных системах персональных данных и к технологиям хранения таких</p>
                    <p> данных вне информационных систем персональных данных;</p>
                    <p> 3.6. Не передавать персональные данные третьим лицам по открытым каналам</p>
                    <p> телефонной, телеграфной и факсимильной связи, а также с использованием сети</p>
                    <p> Интернет без принятия мер, обеспечивающих защиту персональных данных;</p>
                    <p> 3.7. Вести учет сотрудников, которые осуществляют обработку персональных данных.</p>
                    <p> К обработке персональных данных допускать сотрудников, предоставивших</p>
                    <p> письменное согласие соблюдать требования по обеспечению безопасности</p>
                    <p> персональных данных;</p>
                    <p> 3.8. Предоставлять Субъекту персональных данных при его обращении или при</p>
                    <p> получении от него запроса информацию, касающуюся обработки его персональных</p>
                    <p> данных, в порядке и на условиях, установленных Федеральным законом от 27.07.2006г.</p>
                    <p> No 152-ФЗ «О персональных данных»</p>
                    <p> 3.9. Сообщать Субъекту персональных данных о выявленных фактах распространения</p>
                    <p> его персональных данных;</p>
                    <p> 3.10. Уведомить уполномоченный орган по защите прав субъектов персональных</p>
                    <p> данных о намерении осуществлять обработку персональных данных. Обеспечить</p>
                    <p> возможность контроля со стороны уполномоченных федеральных органов</p>
                    <p> исполнительной власти за соблюдением правил обработки персональных данных,</p>
                    <p> установленных законодательством;</p>
                    <p> 4. Субъект персональных данных подтверждает свою дееспособность и достоверность</p>
                    <p> предоставленных Оператору персональных данных;</p>
                    <p> 5. Оператор не вправе без разрешения Субъекта персональных данных разглашать</p>
                    <p> или иным способом раскрывать его персональные данные третьим лицам. Оператор,</p>
                    <p> нарушивший конфиденциальность персональных данных, обязуется возместить</p>
                    <p> убытки, понесенные Субъектом персональных данных в связи с распространением</p>
                    <p> персональных данных в соответствии с действующим законодательством Российской</p>
                    <p> Федерации;</p>
                    <p> 6. Соглашение между Оператором и Субъектом персональных данных заключается</p>
                    <p> путем принятия Субъектом условий настоящей оферты при регистрации аккауната,</p>
                    <p> и/или оформления заказа, и/или отправки заявки на предоставления услуги на Сервисе</p>
                    <p> и является бессрочным, до момента пока одна из сторон не заявит второй стороне о</p>
                    <p> прекращении действия Соглашения. Соглашение считается расторгнутым в течение 5</p>
                    <p> (Пять) дней после направления соответствующего уведомления.</p>
                    <p> 7. Оператор вправе вносить изменения в данное Соглашение в одностороннем порядке</p>
                    <p> без предварительного уведомления Пользователя. При внесении существенных</p>
                    <p> изменений в порядок использования личной информации Оператор обязуется</p>
                    <p> уведомить Субъектов и/или опубликовав объявление на Сайте или отправив</p>
                    <p> сообщение по электронной почте, предоставленной Субъектом Оператору.</p>
                    <p> 9. Дополнительные вопросы, а также требование об уничтожении, изменении ранее</p>
                    <p> представленных, персональных данных, Субъект может направить Оператору на сайте</p>
                    <p> Оператора по адресу https://viksi.me или по электронной почте 666viksi666@gmail.com</p>
                </div>
            </div>
            <MoreProducts />
            <Social light />
        </div>
    )
}

export default Doc3Page;