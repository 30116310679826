import ym from "react-yandex-metrika";
import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import { AuthDispatchContext, AuthContext } from "../context/AuthContext";
import axios from "axios";
import { ProductContext } from "../context/ProductContext";
import { useTranslation } from "react-i18next";


const LanguageToggle: React.FC = () => {
    const { t, i18n } = useTranslation();

    function toggleLanguage(){
        i18n.changeLanguage(i18n.language === "en" ? "ru" : "en");
    }

    return (
        <span className="language-toggle" onClick={toggleLanguage}>
            {t("toggle_language")}
        </span>
    )
}

const ConditionalNav: React.FC = () => {
    const dispatch = useContext(AuthDispatchContext);
    const navigate = useNavigate();   

    const { t } = useTranslation();
    const {isLoggedIn} = useContext(AuthContext);

    function Logout(){
        axios.post("/api/auth/logout").then(() => {
            dispatch({type: "logout"});
            navigate("/login");
        })
    }
    
    return (
        <div className="nav">
            <Link to="/catalog" className="nav-link" onClick={() => ym('hit', `navigation-catalog`)}>
                <span>{t("nav_catalog")}</span>
            </Link>
            {
                isLoggedIn ?
                <>
                    <Link to="/dashboard" className="nav-link"  onClick={() => ym('hit', `navigation-dashboard`)}>
                        <span>{t("nav_dashboard")}</span>
                    </Link>
                    <Link to="/profile" className="nav-link"  onClick={() => ym('hit', `navigation-profile`)}>
                        <span>{t("nav_profile")}</span>
                    </Link>
                    <a onClick={Logout} className="nav-link">
                        <span>{t("nav_logout")}</span>
                    </a>
                </>
                :
                <>
                    <Link to="/login" className="nav-link" onClick={() => ym('hit', `navigation-login`)}>
                        <span>{t("nav_login")}</span>
                    </Link>
                    <Link to="/signup" className="nav-link" onClick={() => ym('hit', `navigation-signup`)}>
                        <span>{t("nav_signup")}</span>
                    </Link>
                </>
            }
            <LanguageToggle />
        </div>
    )
}

const Header: React.FC<{}> = () => {
    const [mobileMenuOpened, setMobileMenuOpened] = useState(false);
    const { cart } = useContext(ProductContext); 

    return (
        <>
        <header className="site-header">
            <nav className="container">
                <div className="logo-wrapper">
                    <Link to="/" ><img src="/img/logo.svg" alt="" className="logo"  onClick={() => ym('hit', `navigation-logo`)}/></Link>
                </div>
                <Link to="/cart" className="nav-link cart"  onClick={() => ym('hit', `navigation-cart`)}>
                    <img className="icon" alt="" src="/icon/cart.svg" />
                    { cart.length ? <div className="count">{cart.length}</div> : <></> }
                </Link>
                <ConditionalNav />
                <div className="mobile-menu-toggle" onClick={() => setMobileMenuOpened((opened) => !opened)}>
                    <div className="line" />
                    <div className="line" />
                    <div className="line" />
                </div>
            </nav>
        </header>
        <div className={`mobile-menu-wrapper ${mobileMenuOpened ? "opened" : ""}`}>
            <div className="mobile-menu">
                <nav className="header-nav" onClick={() => setMobileMenuOpened(false)}>
                    <ConditionalNav />
                </nav>
            </div>
        </div>
        </>
    )
}

export default Header;