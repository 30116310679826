import { createContext, useEffect, useReducer, Dispatch } from "react";

import axios from "axios";
import { IProduct } from "../api/type";

export interface IUser {
    _id: string
    email: string
    email_verified: boolean
    phone: string | null
    phone_verified: boolean
    products: IProduct[]
}

export interface IAuthContext {
    isLoaded: boolean
    isLoggedIn: boolean
    user: null | IUser
}

type IAuthAction = {
    type: "login",
    user: IUser
} | {
    type: "logout"
} | {
    type: "loaded"
} | {
    type: "update",
    user: Partial<IUser>
}

const defaultAuthState = {
    isLoaded: false,
    isLoggedIn: false,
    user: null
};

export const AuthContext = createContext<IAuthContext>(defaultAuthState);
export const AuthDispatchContext = createContext<Dispatch<IAuthAction>>(() => {});

function authReducer(state: IAuthContext, action: IAuthAction) {
    switch (action.type) {
      case 'login': {
        return {...state, user: action.user, isLoggedIn: true}
      }
      case 'logout': {
        return {...state, user: null, isLoggedIn: false}
      }
      case 'loaded': {
        return {...state, isLoaded: true}
      }
      case 'update': {
        if(state.user){
            const updatedUser: IUser = {...state.user, ...action.user};
            return ({
                ...state,
                user: updatedUser
            })
        } else {
            return state;
        }
      }
      default: {
        return state
      }
    }
}


export const AuthContextProvider: React.FC<{children: any}> = ({children}) => {
    const [authState, dispatch] = useReducer(authReducer, defaultAuthState);

    console.log(authState);

    useEffect(() => {
        axios.get("/api/auth/current", {withCredentials: true}).then(({data}) => {
            data.user ? dispatch({type: "login", user: data.user}) : dispatch({type: "loaded"})
        }).catch(error => {
            return false
        }).finally(() => {
            dispatch({type: "loaded"})
        })
    }, []);

    return (
        <AuthDispatchContext.Provider value={dispatch}>
            <AuthContext.Provider value={authState}>
                {children}
            </AuthContext.Provider>
        </AuthDispatchContext.Provider>
    )
}