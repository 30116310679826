import axios from "axios";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

function setCookie(cName: string, cValue: string, expDays: number) {
    let date = new Date();
    date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000));
    const expires = "expires=" + date.toUTCString();
    document.cookie = cName + "=" + cValue + "; " + expires + "; path=/";
}

export const RefController: React.FC = () => {
    const [query] = useSearchParams();

    const countVisit = (token: string) => {
        axios({
            url: `/api/partner/count/${token}`,
            method: "post"
        }).catch(() => {
            console.error("token is unavailable")
        });
    }
    
    useEffect(() => {
        const ref = query.get("ref");
        if(ref){
            setCookie("ref", ref, 30);
            countVisit(ref)

        }
    }, [query]);

    return null;
}