import { Link } from "react-router-dom";
import Header from "../components/Header";
import Social from "../components/Social";
import { useTranslation } from "react-i18next";
import ym from "react-yandex-metrika";

const FailPage: React.FC<{}> = () => {
    const { t } = useTranslation();

    return (
        <div className="page-wrapper">
            <Header />
            <div className="light-bg full-page-block">
                <div className="fail-page status-page">
                    <img src="/img/face-tired.svg" className="icon" />
                    <div className="text">{t("payment_fail")}</div>
                    <Link className="ghost-btn" to="/cart"  onClick={() => ym('hit', `navigation-catalog`)}>{t("back_to_cart")}</Link>
                </div>
            </div>
            <Social />
        </div>
    )
}

export default FailPage;