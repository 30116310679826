import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ym from "react-yandex-metrika";


const Social: React.FC<{light?: boolean}> = ({light = false}) => {
    const { t } = useTranslation();

    return (
        <section className={`social-section ${light ? "light" : ""}`}>
            <div className="container">
                <a href="https://instagram.com/viksi666" className="social-icon-wrapper">
                    <img className="social-icon" src={light ? "/icon/ig-light.svg" : "/icon/ig.svg"} alt="ig" />
                </a>
                <a href="https://vk.com/viksi666" className="social-icon-wrapper">
                    <img className="social-icon" src={light ? "/icon/vk-light.svg" : "/icon/vk.svg"} alt="vk" />
                </a>
                <a href="https://facebook.com/viksi666" className="social-icon-wrapper">
                    <img className="social-icon" src={light ? "/icon/fb-light.svg" : "/icon/fb.svg"} alt="fb" />
                </a>

                <a href="https://youtube.com/viksi666" className="social-icon-wrapper">
                    <img className="social-icon" src={light ? "/icon/youtube-light.svg" : "/icon/youtube.svg"} alt="youtube" />
                </a>            </div>
            <div className="container">
                <div className="footer-links">
                    <span className="adults link">{t("adults")}</span>
                        <Link className="link" to="/policy"  onClick={() => ym('hit', `navigation-policy`)}>{t("policy_link")}</Link>
                    <a className="mailto link" href="mailto:info@viksi.me">info@viksi.me</a>
                </div>
            </div>
        </section>
    )
}

export default Social;