import Header from "../../components/Header";
import Breadcrumb from "../../components/Breadcrumb";
import { MoreProducts } from "../../components/Products";
import Social from "../../components/Social";

const path = [
    {
        title: "Главная",
        link: "/"
    },
    {
        title: "Политики и оферта",
        link: "/policy"
    },
    {
        title: "Соглашение"
    }
]

const Doc2Page: React.FC<{}> = () => {
    return (
        <div className="page-wrapper">
            <Header />
            <div className="container light-bg">
                <Breadcrumb path={path} />
                <div className="title-wrapper">
                    <h2 className="title">Соглашение по использованию сервиса</h2>
                </div>
                <div className="doc">
                    <p>Настоящее соглашение, являясь пользовательским соглашением (далее - Соглашение), заключенным между физическим лицом, действующим в своих интересах или в интересах других лиц (далее - Пользователь) и Индивидуальный предприниматель Хворостин И.А., являющимся правообладателем исключительного права на Интернет-сайт: viksi.me (далее по тексту – «Сайт»), в дальнейшем совместно именуемых «Стороны», определяет права и обязанности Пользователя и Правообладателя при использовании Сайта. </p>
                    <h4>ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ </h4>
                    <p>Исполнитель / Продавец / Владелец сайта - Индивидуальный предприниматель Хворостин И.А.. </p>
                    <p>Администрация сайта - уполномоченные сотрудники Индивидуальный предприниматель Хворостин И.А.. </p>
                    <p>Интернет-сервис - интернет-сайт, принадлежащий Исполнителю, </p>
                    <p>размещенный в сети Интернет по адресу: viksi.me , на котором представлены </p>
                    <p>услуги Тренинг- центра, предлагаемые Пользователю, а также способы </p>
                    <p>оплаты. </p>
                    <p>Сайт / Интернет-сайт / Сайт интернет-сервиса - совокупность программ для </p>
                    <p>ЭВМ, информации, иного Содержания сайта, размещенных в сети Интернет по </p>
                    <p>адресу: https://viksi.me. </p>
                    <p>Пользователь – женщины, достигшие восемнадцати лет, имеющие доступ к </p>
                    <p>Сайту (независимо от факта регистрации на Сайте) и пользующееся Сайтом. </p>
                    <p>Заказчик / Потребитель – женщины, достигшие восемнадцати лет, имеющие </p>
                    <p>намерение приобрести, либо заказывающие, приобретающие или </p>
                    <p>использующие Услуги Владельца сайта, исключительно для личных и иных </p>
                    <p>нужд, не связанных с осуществлением предпринимательской деятельности. </p>
                    <p>Получатель — женщины, достигшие восемнадцати лет, указанные </p>
                    <p>Заказчиком в качестве приобретателя Услуг. </p>
                    <p>Стороны - стороны, заключившие соглашение между Потребителем и </p>
                    <p>Исполнителем, дистанционным способом. </p>
                    <p>Содержание сайта / Содержание Интернет-сервиса / Содержание </p>
                    <p>охраняемые результаты интеллектуальной деятельности, включая тексты </p>
                    <p>авторских произведений, их названия, предисловия, аннотации, статьи, </p>
                    <p>иллюстрации, обложки, музыкальные произведения с текстом или без текста, </p>
                    <p>графические, текстовые, фотографические, производные, составные и иные </p>
                    <p>произведения, пользовательские интерфейсы, визуальные интерфейсы, названия </p>
                    <p>товарных знаков, логотипы, программы для ЭВМ, базы данных, а также дизайн, </p>
                    <p>структура, выбор, координация, внешний вид, общий стиль и расположение </p>
                    <p>данного Содержания , входящего в состав Сайта и другие объекты интеллектуальной собственности все вместе и/или по отдельности, содержащиеся на сайте Интернет-сервиса. </p>
                    <p>Услуги – интерактивное обучение, направленное на формирование и </p>
                    <p>совершенствование определенных умений, навыков в форме тренингов, лекций, </p>
                    <p>мастер-классов. </p>
                    <p>Учетная запись – учетная запись Пользователя, содержащая информацию о </p>
                    <p>предоставленных Пользователем персональных данных, а также иные сведения, </p>
                    <p>фиксирование которых Правообладатель сочтет необходимым в целях </p>
                    <p>использования Сайта Пользователем. </p>
                    <p>Контент – содержание Сайта. </p>
                    <p>Запись на Тренинг – выбор Тренинга Пользователем, с указанием названия, </p>
                    <p>даты, выбор способа оплаты. из числа предложенных вариантов, совершенные </p>
                    <p>путем заполнения специальной формы, размещенной на сайте. </p>
                    <p>1. </p>
                    <p>ОБЩИЕ ПОЛОЖЕНИЯ </p>
                    <p>1.1. Пользователь до отправления формы заявки на предоставление услуг на Сайт в последующем обязан ознакомиться с текстом настоящего Соглашения и другими </p>
                    <p>документами. После отправления формы на Сайте Пользователь лишается </p>
                    <p>возможности ссылаться на неосведомленность по каким-либо вопросам, </p>
                    <p>урегулированным настоящим Соглашением или иными документами раздела. </p>
                    <p>1.2. Настоящее Соглашение регулирует отношения между Администрацией сайта и </p>
                    <p>Пользователем данного Сайта. </p>
                    <p>1.3. Администрация сайта оставляет за собой право в любое время изменять, добавлять </p>
                    <p>или удалять пункты настоящего Соглашения без уведомления Пользователя. </p>
                    <p>1.4. Направление формы заявки на Сайт, Пользователь выражает свое полное и </p>
                    <p>безоговорочное согласие со всеми условиями текущего Соглашения. Пользователь </p>
                    <p>обязан полностью ознакомиться с настоящим Соглашением до момента отправления </p>
                    <p>формы на Сайте, подтверждение которого означает полное и безоговорочное </p>
                    <p>принятие Пользователем условий использования Интернет-сервиса в соответствии с условиями настоящего Соглашения </p>
                    <p>1.5. Пользователь несет персональную ответственность за ознакомление с настоящим </p>
                    <p>Соглашением и его изменениями. Новая редакция Соглашения вступает в силу с </p>
                    <p>момента ее размещения на данной странице, если иное не предусмотрено новой </p>
                    <p>редакцией Соглашения. </p>
                    <p>2. ПРЕДМЕТ СОГЛАШЕНИЯ </p>
                    <p>2.1. Предметом настоящего Соглашения является предоставление Пользователю Сайта </p>
                    <p>(Интернет-сервиса) доступа к программному обеспечению для оказания Услуг </p>
                    <p>представленных Тренинг-центром. Сайт предназначен для информирования </p>
                    <p>Пользователя о реализуемых посредством Сайта работах, услугах, для обеспечения </p>
                    <p>возможности дальнейшего приобретения Пользователем Услуг Тренинг-центра. </p>
                    <p>2.1.1. Интернет-сервис предоставляет Пользователю следующие виды услуг </p>
                    <p>(сервисов): </p>
                    <p>доступ к электронному контенту на бесплатной основе, с правом просмотра </p>
                    <p>контента; </p>
                    <p>доступ к средствам поиска и навигации Интернет-сервиса; </p>
                    <p>доступ к информации о работах, услугах; </p>
                    <p>доступ к информации о способах и условиях оплаты;</p>
                    <p>осуществление поиска, в соответствии с запросом Пользователя среди </p>
                    <p>размещенных на Сайте предложений; </p>
                    <p>запись на пользование Услугами; </p>
                    <p>получение информации о записи на Тренинги; </p>
                    <p>получение новостей об услугах, предложениях, рекламных акциях и скидках </p>
                    <p>Интернет-сервиса и/или его Партнеров; </p>
                    <p>иные виды услуг (сервисов), реализуемые на страницах Интернет-сервиса. </p>
                    <p>2.1.2. Под действие настоящего Соглашения подпадают все существующие (реально </p>
                    <p>функционирующие) на данный момент услуги (сервисы) Сайта, а также любые их </p>
                    <p>последующие модификации и появляющиеся в дальнейшем дополнительные услуги </p>
                    <p>(сервисы) Сайта, Интернет-сервиса. </p>
                    <p>2.2. Доступ к Сайту, Интернет-сервису предоставляется на бесплатной основе , </p>
                    <p>исключительно женщинам, достигшим 18 лет. Отношения по приобретению Услуг, </p>
                    <p>носящие возмездный характер, урегулированы отдельным документом – публичной </p>
                    <p>офертой. </p>
                    <p>3.ОБЩИЕ УСЛОВИЯ ИСПОЛЬЗОВАНИЯ САЙТА </p>
                    <p>3.1. Интернет-сервис предоставляет Пользователю возможность приобретения Услуг предоставляемых Тренинг-центром, представленных на Сайте. Приобретение Услуг возможно на условиях публичной оферты, представленной на Сайте. </p>
                    <p>3.2. Запись на получение Услуг, предлагаемых на Сайте, не требует регистрации </p>
                    <p>Пользователя. При заполнении формы заявки на участие в Тренинге, Пользователь </p>
                    <p>указывает имя, адрес электронной почты,номер телефона, на который </p>
                    <p>Администрацией Сайта отправляется письмо для подтверждения записи на Тренинги. </p>
                    <p>3.3. Для формирования Заказа на Сайте Пользователь должен осуществить следующие </p>
                    <p>действия: </p>
                    <p>3.3.1. выбрать вид Услуг, предлагаемых на Сайте; </p>
                    <p>433.2 выбрать дату посещения; </p>
                    <p>3.3.3. отправить заявку на участие; </p>
                    <p>3.3.4. указать на странице «сертификаты» промо-код или сертификат, чтобы получить </p>
                    <p>специальные условия для заказа (при наличии); </p>
                    <p>3.3.5. выбрать способ оплаты: безналичный или наличными. </p>
                    <p>3.3.6. подтверждения Заявки на участие необходимо отметить галочками: </p>
                    <p>«Я принимаю условия пользовательского соглашения». </p>
                    <p>4.4. Порядок обработки Интернет-сервисом информации, указанной в п. 3.2. </p>
                    <p>3.3.,урегулирован Соглашением о соблюдении конфиденциальности персональных </p>
                    <p>данных и Положением по обработке персональных данных, размещенных на Сайте. </p>
                    <p>3.5.. Пользователь приобретает права на Услуги на основании Публичной оферты. </p>
                    <p>3.6. Интернет-сервис не несет ответственность за деятельность третьих лиц (платежных сервисов, служб доставки и др.), но может принимать непосредственное участие в рассмотрении любых споров, связанных с осуществлением их деятельности, если это имеет отношение к сделкам, осуществляемым на Сайте. </p>
                    <p>3.7. Для использования Сайта необходимо подключение к Интернету. Интернет-сервис </p>
                    <p>не несет ответственность за возможные неблагоприятные последствия для </p>
                    <p>Пользователя вызванные, невозможностью подключения к Интернету. </p>
                    <p>3.8. Содержание Сайта не может быть скопировано, опубликовано, воспроизведено, передано или распространено любым способом, а также размещено в глобальной сети Интернет без предварительного письменного согласия Администрации сайта. </p>
                    <p>3.9. Содержание Сайта защищено авторским правом, законодательством о товарных знаках, а также другими нормами законодательства Российской Федерации, относящимся к защите интеллектуальной собственности, и защите от </p>
                    <p>недобросовестной конкуренции </p>
                    <p>3.10. Настоящее Соглашение распространяет свое действия на все дополнительные положения и условия предоставляемых на Сайте Услуг. </p>
                    <p>3.11. Информация, размещаемая на Сайте, не должна истолковываться как изменение настоящего Соглашения. </p>
                    <p>3.12. Администрация сайта имеет право в любое время без уведомления Пользователя вносить изменения в перечень предлагаемых Услуг Интернет-сервиса, предлагаемых на Сайте, и (или) в цены, применимые к оказываемым Услугам Интернет-сервисом. </p>
                    <p>3.13. Отношения между Владельцем и Пользователем регулируются настоящим </p>
                    <p>Соглашением, а также иными документами, размещенными на Сайте. </p>
                    <p>3.14. Любой из документов Сайта может подлежать обновлению. Изменения вступают в силу с момента их опубликования на Сайте. </p>
                    <p>3.15. Пользователь может указать на Сайте ссылку на свою(и) страницу(ы) в </p>
                    <p>социальных сетях (Вконтакте, Facebook, Яндекс, Instagram, Mail.ru Одноклассники, </p>
                    <p>Вконтакте, twitter, facebook и т.д.). Все персональные данные социальных сетей </p>
                    <p>признаются общедоступными и Исполнитель не несет ответственности за их обработку </p>
                    <p>и возможное использование их третьими лицами. </p>
                    <p>4. ДОПУСТИМОЕ ИСПОЛЬЗОВАНИЕ САЙТА И ГАРАНТИИ </p>
                    <p>ПОЛЬЗОВАТЕЛЯ </p>
                    <p>4.1. Пользователь гарантирует, что он является единственным законным обладателем данных, размещаемых им на Сайте, что владеет исключительными правами на </p>
                    <p>использование, распространение.. </p>
                    <p>4.2.Пользователь обязуется не использовать Сайт, в нарушение прав и законных </p>
                    <p>интересов третьих лиц и законодательства Российской Федерации, в том числе: </p>
                    <p>4.2.1. не размещать на Сайте материалы, содержащие оскорбления, клевету, </p>
                    <p>нецензурные выражения, порнографические или иные противоречащие нормам морали материалы; материалы, демонстрирующие или пропагандирующие жестокость, террор или насилие, оскорбляющие человеческое достоинство, а также иные материалы, не соответствующие законодательству Российской Федерации или ссылки на интернет- сайты и другие ресурсы, содержащие такие материалы; </p>
                    <p>4.2.2. не размещать любую информацию и материалы, которые содержат угрозы, </p>
                    <p>дискредитируют или оскорбляют других Пользователей или третьих лиц, носят </p>
                    <p>мошеннический характер, посягают на личные или публичные интересы, </p>
                    <p>пропагандируют расовую, религиозную, этническую ненависть или вражду, а также </p>
                    <p>любую иную информацию, нарушающую охраняемые законодательством Российской Федерации права человека и гражданина; </p>
                    <p>4.2.3. не осуществлять пропаганду или агитацию, возбуждающую социальную, </p>
                    <p>расовую, национальную или религиозную ненависть и вражду, ненависть к лицам </p>
                    <p>нестандартной сексуальной ориентации, пропаганду войны, социального, расового, </p>
                    <p>национального, религиозного или языкового превосходства; </p>
                    <p>4.2.4. не описывать или пропагандировать преступную деятельность, не размещать </p>
                    <p>инструкции или руководства по совершению преступных действий. </p>
                    <p>4.2.5. не размещать и не передавать посредством Сайта любую информацию </p>
                    <p>ограниченного доступа (конфиденциальную информацию); </p>
                    <p>4.2.6. не распространять посредством Сайта спам, сообщения-цепочки (сообщения, </p>
                    <p>требующие их передачи одному или нескольким пользователям), схемы финансовых пирамид или призывы в них участвовать, а также любую другую навязчивую информацию; </p>
                    <p>4.2.7. не размещать посредством Сайта сообщения, графические изображения, </p>
                    <p>фотоматериалы или другие материалы, размещение которых наносит или может </p>
                    <p>нанести ущерб чести, достоинству и деловой репутации какого-либо физического или юридического лица. </p>
                    <p>4.2.8. не размещать посредством Сайта персональные данные, в том числе домашние адреса, номера телефонов, адреса электронной почты, паспортные данные и прочую личную информацию (персональные данные) других Пользователей или иных лиц без их предварительного согласия. </p>
                    <p>4.2.9. не размещать посредством Сайта результаты любой интеллектуальной </p>
                    <p>деятельности, права на использование которых подобным способом отсутствуют у </p>
                    <p>Пользователя. </p>
                    <p>4.2.10. не размещать на сайте Контент, содержащий изображения физических лиц, без их предварительного согласия за исключением случаев, предусмотренных законодательством Российской Федерации. </p>
                    <p>4.2.11. не размещать на сайте контент, содержащий предвыборные агитационные </p>
                    <p>материалы. </p>
                    <p>4.3. Администрация Сайта имеет неограниченный доступ к личному профилю </p>
                    <p>Пользователя, иным данным, размещенным Пользователем посредством Сайта. </p>
                    <p>4.4. Администрация Сайта вправе удалить профиль Пользователя при наличии </p>
                    <p>обстоятельств, свидетельствующих о том, что профиль используется в мошеннических, преступных, террористических целях, нарушает права и законные интересы третьих лиц, любым иным способом способствует или может способствовать нарушению законодательства Российской Федерации. </p>
                    <p>5.ПРАВА И ОБЯЗАННОСТИ СТОРОН </p>
                    <p>5.1. Администрация сайта вправе: </p>
                    <p>5.1.1. Администрация вправе предоставить Пользователю доступ к Сайту и </p>
                    <p>поддерживать Сайт и Сервисы в рабочем состоянии, изменять правила пользования Сайтом, а также изменять содержание данного Сайта. Изменения вступают в силу с момента публикации новой редакции Соглашения на Сайте. </p>
                    <p>5.1.2. Администрация оставляет за собой право следить за деятельностью Пользователя в рамках использования им Сайта и Сервисов и предотвращать публикацию любых материалов, нарушающих настоящее Соглашение, а также принимать меры по привлечению Пользователя к ответственности, предусмотренной настоящим Соглашением и находящейся в компетенции Администрации. </p>
                    <p>5.1.3. Администрация вправе осуществлять пост модерацию личного профиля и </p>
                    <p>материалов Пользователя после публикации, в рамках которой: </p>
                    <p>в случае размещения Пользователем на Сайте информации или совершения </p>
                    <p>иных действий, несоответствующих условиям Соглашения, Администрация </p>
                    <p>имеет право без уведомления, по собственному усмотрению удалить полностью </p>
                    <p>или частично размещенную Пользователем информацию, включая ту </p>
                    <p>информацию, в отношении которой затруднительно определить ее соответствие </p>
                    <p>Соглашению и/или применимому законодательству РФ; </p>
                    <p>за нарушение условий настоящего Соглашения Администрация имеет право без </p>
                    <p>предварительного уведомления блокировать доступ Пользователя к Сайту.. </p>
                    <p>5.1.4. Администрация вправе использовать (обрабатывать и т.п.) персональные данные, указанные Пользователем, а также направлять на предоставленный Пользователем электронный адрес и размещать в пространстве, ограниченном доступом Пользователя, рекламные и информационные сообщения по своему усмотрению, рекламные материалы партнеров Администрации. </p>
                    <p>5.1.5. Исполнитель вправе переуступать либо каким-либо иным способом передавать </p>
                    <p>свои права и обязанности, вытекающие из его отношений с Пользователем, третьим лицам без согласия Пользователя. </p>
                    <p>5.2. Пользователь вправе: </p>
                    <p>5.2.1. Пользоваться всеми имеющимися на Сайте услугами, а также приобретать </p>
                    <p>любую Услугу , предлагаемую на Сайте. </p>
                    <p>5.2.2. Задавать вопросы, относящиеся к услугам Интернет-сервиса. </p>
                    <p>5.2.3. Пользоваться Сайтом исключительно в целях и порядке, предусмотренных </p>
                    <p>Соглашением и не запрещенных законодательством Российской Федерации. </p>
                    <p>5.3. Пользователь Сайта обязуется: </p>
                    <p>5.3.1. Предоставлять по запросу Администрации сайта дополнительную информацию, которая имеет непосредственное отношение к предоставляемым услугам данного Сайта. </p>
                    <p>5.3.2. Соблюдать имущественные и неимущественные права авторов и иных </p>
                    <p>правообладателей при использовании Сайта. </p>
                    <p>5.3.3. Не предпринимать действий, которые могут рассматриваться как нарушающие нормальную работу Сайта. </p>
                    <p>5.3.4. Не распространять с использованием Сайта любую конфиденциальную и </p>
                    <p>охраняемую законодательством Российской Федерации информацию о физических либо юридических лицах. </p>
                    <p>5.3.5. Избегать любых действий, в результате которых может быть нарушена </p>
                    <p>конфиденциальность охраняемой законодательством Российской Федерации </p>
                    <p>информации. </p>
                    <p>5.3.6. Не использовать Сайт для распространения информации рекламного характера, иначе как с согласия Администрации сайта. </p>
                    <p>5.3.7. Не использовать сервисы сайта с целью: </p>
                    <p>5.3.7.1. загрузки контента, который является незаконным, нарушает любые права </p>
                    <p>третьих лиц; пропагандирует насилие, жестокость, ненависть и (или) дискриминацию </p>
                    <p>по расовому, национальному, половому, религиозному, социальному признакам; </p>
                    <p>содержит недостоверные сведения и (или) оскорбления в адрес конкретных лиц, </p>
                    <p>организаций, органов власти. </p>
                    <p>5.3.7.2. побуждения к совершению противоправных действий, а также содействия </p>
                    <p>лицам, действия которых направлены на нарушение ограничений и запретов, </p>
                    <p>действующих на территории Российской Федерации. </p>
                    <p>5.3.7.3. нарушения прав несовершеннолетних лиц и (или) причинение им вреда в </p>
                    <p>любой форме. </p>
                    <p>5.3.7.4. ущемления прав меньшинств. </p>
                    <p>5.3.7.5. представления себя за другого человека или представителя организации и (или) сообщества без достаточных на то прав, в том числе за сотрудников данного Интернет- сервиса. </p>
                    <p>5.3.7.6. введения в заблуждение относительно свойств и характеристик Товаров или Услуг Интернет-сервиса, размещенного на Сайте. </p>
                    <p>5.4. Пользователю запрещается: </p>
                    <p>5.4.1. Использовать любые устройства, программы, процедуры, алгоритмы и методы, автоматические устройства или эквивалентные ручные процессы для доступа, приобретения, копирования или отслеживания содержания Сайта; </p>
                    <p>5.4.2. Нарушать надлежащее функционирование Сайта; </p>
                    <p>5.4.3. Любым способом обходить навигационную структуру Сайта для получения или попытки получения любой информации, документов или материалов любыми </p>
                    <p>средствами, которые специально не представлены сервисами данного Сайта; </p>
                    <p>5.4.4. Несанкционированный доступ к функциям Сайта, любым другим системам или </p>
                    <p>сетям, относящимся к данному Сайту, а также к любым услугам, предлагаемым на </p>
                    <p>Сайте; </p>
                    <p>5.4.5. Нарушать систему безопасности или аутентификации на Сайте или в любой сети, относящейся к Сайту. </p>
                    <p>5.4.6. Размещать на Сайте Информацию, нарушающую авторские и/или иные права </p>
                    <p>третьих лиц и/или правообладателей, права на товарные знаки, права на изобретение, </p>
                    <p>полезную модель или промышленный образец и права на иные результаты </p>
                    <p>интеллектуальной деятельности, принадлежащие Администрации и/или третьим </p>
                    <p>лицам; </p>
                    <p>5.4.7. Размещать на Сайте информацию, нарушающую достоинство личности, честь и доброе имя, деловую репутацию, неприкосновенность частной жизни, личную и </p>
                    <p>семейную тайну третьих лиц); </p>
                    <p>5.4.8. Распространять спам, а также любую другую навязчивую информацию, явно не запрошенную другими Пользователями; </p>
                    <p>5.4.9. Размещать любые файлы, которые содержат или могут содержать вирусы и </p>
                    <p>другие вредоносные программы; </p>
                    <p>5.4.10. Размещать на Сайте домашние адреса, номера телефонов, адреса электронной почты, паспортные данные и прочую личную информацию других Пользователей или любых третьих лиц без их личного согласия на такие действия; </p>
                    <p>5.4.11. Использовать Сайт и его Содержание в любых целях, запрещенных </p>
                    <p>законодательством Российской Федерации, а также подстрекать к любой незаконной деятельности или другой деятельности, нарушающей права Администрации Сайта или других лиц. </p>
                    <p>6. ОТВЕТСТВЕННОСТЬ </p>
                    <p>6.1. Любые убытки, которые Пользователь может понести в случае умышленного или неосторожного нарушения любого положения настоящего Соглашения, а также </p>
                    <p>вследствие несанкционированного доступа к коммуникациям другого Пользователя, либо вследствие действий других Пользователей или третьих лиц, Администрацией сайта не возмещаются. </p>
                    <p>6.2. Администрация сайта не несет ответственности за: </p>
                    <p>6.2.1. Задержки или сбои в процессе совершения операции, возникшие вследствие </p>
                    <p>непреодолимой силы, а также любого случая неполадок в телекоммуникационных, </p>
                    <p>компьютерных, электрических и иных смежных системах. </p>
                    <p>6.2.2. Действия систем переводов, банков, платежных систем и за задержки, связанные с их работой. </p>
                    <p>6.2.3. Ненадлежащее функционирование Сайта, в случае, если Пользователь не имеет необходимых технических средств для его использования, а также не несет никаких </p>
                    <p>обязательств по обеспечению пользователей такими средствами. </p>
                    <p>6.2.4. Невозможности оформления Заявки Пользователем на Сайте по любым </p>
                    <p>причинам. </p>
                    <p>6.2.5. Действия Почты, курьерской службы, платежных сервисов и иных третьих лиц. </p>
                    <p>6.3. Пользователь несет ответственность за любые действия по размещению любого </p>
                    <p>Контента и иной информации на Сайте, за передачу или доведение до сведения других Пользователям и иных лиц данной информации или Контента, а также за любые взаимодействия с другими лицами/Пользователями в рамках Сайта. </p>
                    <p>6.4. Пользователь гарантирует, что не будет предпринимать каких-либо действий, </p>
                    <p>направленных на причинение ущерба обладателю прав на Сайт, операторам сотовой мобильной связи, правообладателям и иным лицам. </p>
                    <p>6.5. Признавая международный характер сети Интернет, Пользователь принимает на себя ответственность за соблюдение всех соответствующих правил и законов, </p>
                    <p>касающихся действий Пользователя в сети Интернет. </p>
                    <p>6.6. Владелец Сайта не несет ответственности за любые ошибки, упущения, </p>
                    <p>прерывания, удаление, дефекты, задержку в обработке или передаче данных, сбое </p>
                    <p>линий связи, кражу, уничтожение или неправомерный доступ к материалам </p>
                    <p>пользователей, размещенным на Сайте или в любом другом месте. </p>
                    <p>6.7. Владелец Сайта не отвечает за любые технические сбои или иные проблемы </p>
                    <p>любых телефонных сетей или служб, компьютерных систем, серверов или </p>
                    <p>провайдеров, компьютерного или телефонного оборудования, программного </p>
                    <p>обеспечения, сбоев сервисов электронной почты или скриптов по техническим </p>
                    <p>причинам. </p>
                    <p>6.8. Владелец Сайта не несет ответственности за любой ущерб компьютеру </p>
                    <p>пользователя или иного лица, мобильным устройствам, любому другому </p>
                    <p>оборудованию или программному обеспечению, вызванный или связанный со </p>
                    <p>скачиванием материалов с Сайта или по ссылкам, размещенным на Сайте. </p>
                    <p>6.9. Владелец Сайта не несет ответственности за наличие на Сайте вирусов, а также за возможные последствия заражения мобильного устройства, компьютера Пользователя вирусами или попадание на мобильное устройство, компьютер Пользователя иных вредоносных программ. Владелец Сайта предоставляет Сайт и программное обеспечение Сайта Пользователю «как есть» без каких-либо дополнительных гарантий. </p>
                    <p>6.10. Владелец Сайта не отвечает, не возмещает и не несет ответственности за любые убытки, включая упущенную выгоду, моральный и иной вред, причиненные </p>
                    <p>Пользователю или третьим лицам в результате использования ими Сайта, содержимого Сайта или иных материалов, к которым был получен доступ с помощью Сайта или в связи с функционированием Сайта, а также во всех иных случаях, предусмотренных настоящим Соглашением. </p>
                    <p>6.11..При любых обстоятельствах ответственность Владельца Сайта в соответствии со статьей 15 Гражданского кодекса России ограничена 1 000 (одной тысячей) рублей РФ </p>
                    <p>и возлагается на него при наличии в его действиях вины. </p>
                    <p>7. НАРУШЕНИЕ УСЛОВИЙ ПОЛЬЗОВАТЕЛЬСКОГО СОГЛАШЕНИЯ </p>
                    <p>7.1. Администрация сайта вправе раскрыть любую собранную о Пользователе данного Сайта информацию, если раскрытие необходимо в связи с расследованием или жалобой в отношении неправомерного использования Сайта либо для установления (идентификации) Пользователя, который может нарушать или вмешиваться в права </p>
                    <p>Администрации сайта или в права других Пользователей Сайта. </p>
                    <p>7.2. Администрация сайта имеет право раскрыть любую информацию о Пользователе, которую посчитает необходимой для выполнения положений действующего законодательства или судебных решений, обеспечения выполнения условий настоящего Соглашения, защиты прав или безопасности иных Пользователей и любых третьих лиц. </p>
                    <p>7.3. Администрация сайта имеет право раскрыть информацию о Пользователе, если действующее законодательство Российской Федерации требует или разрешает такое раскрытие. </p>
                    <p>7.4. Администрация сайта вправе без предварительного уведомления Пользователя </p>
                    <p>прекратить и (или) заблокировать доступ к Сайту, если Пользователь нарушил </p>
                    <p>настоящее Соглашение или содержащиеся в иных документах условия пользования Сайтом, а также в случае прекращения действия Сайта либо по причине технической неполадки или проблемы. </p>
                    <p>7.5. Администрация сайта не несет ответственности перед Пользователем или </p>
                    <p>третьими лицами за прекращение доступа к Сайту в случае нарушения Пользователем любого положения настоящего Соглашения или иного документа, содержащего условия пользования Сайтом. </p>
                    <p>8. РАЗРЕШЕНИЕ СПОРОВ </p>
                    <p>8.1. В случае возникновения любых разногласий или споров между Сторонами </p>
                    <p>настоящего Соглашения обязательным условием до обращения в суд является </p>
                    <p>предъявление претензии (письменного предложения о добровольном урегулировании спора). </p>
                    <p>8.2. При невозможности разрешить спор в добровольном порядке любая из Сторон </p>
                    <p>вправе обратиться в суд за защитой своих прав, которые предоставлены им </p>
                    <p>действующим законодательством Российской Федерации. </p>
                    <p>9. ДОПОЛНИТЕЛЬНЫЕ УСЛОВИЯ </p>
                    <p>9.1. Администрация сайта не принимает встречные предложения от Пользователя </p>
                    <p>относительно изменений настоящего Пользовательского соглашения. </p>
                    <p>9.2. Пользователь предоставляет свое согласие на использование его персональных </p>
                    <p>данных (Фамилия, имя, отчество, адрес электронной почты, телефон, паспортные </p>
                    <p>данные) для целей исполнения настоящего Соглашения, а также для целей </p>
                    <p>направления рекламных, маркетинговых материалов, с правом передачи персональных </p>
                    <p>данных Пользователя третьим лицам, Партнерам Владельца Сайта. </p>
                    <p>9.3. Пользователь добровольно предоставляет информацию, которая автоматически </p>
                    <p>передается Администрации Сайта в процессе использования Сайта с помощью </p>
                    <p>установленного на устройстве Пользователя программного обеспечения, в том числе IP-адрес, информация cookie, информация о браузере Пользователя, геолокационные данные устройств Пользователя, данные о действиях Пользователя на Сайте, а также иные данные о Пользователе. </p>
                    <p>9.4. По вопросам, связанным с работой Сайта, использованием сервисов Сайта, </p>
                    <p>Пользователь может получить всю необходимую информацию на страницах Сайта. </p>
                    <p>9.5.Порядок подачи претензий регламентирован в: Публичной оферте </p>
                    <p>С претензиями просьба обращаться по адресу: 666viksi666@gmail.com</p>
                    <p>Владелец сайта оставляет за собой право вносить изменения в действующее </p>
                    <p>Соглашение, в связи с чем Пользователь обязуется регулярно отслеживать любые </p>
                    <p>изменения в Соглашении, размещенном на Сайте. </p>
                </div>
            </div>
            <MoreProducts />
            <Social light />
        </div>
    )
}

export default Doc2Page;