import { createContext, useEffect, useReducer, Dispatch } from "react";

import axios from "axios";
import { Bundle, IProduct } from "../api/type";



export interface IProductContext {
    products: IProduct[]
    // bundles: Bundle[]
    cart: string[]
    isLoaded: boolean
}

type IProductAction = {
    type: "LOADED",
    products: IProduct[]
    // bundles: Bundle[]
} | {
    type: "CART_ADD",
    item: string
} | {
    type: "CART_REMOVE",
    item: string
} | {
    type: "CART_CLEAR"
}

const defaultProductState = {
    isLoaded: false,
    products: [],
    // bundles: [],
    cart: []
};

export const ProductContext = createContext<IProductContext>(defaultProductState);
export const ProductDispatchContext = createContext<Dispatch<IProductAction>>(() => {});

function saveCart(cart: string[]){
    localStorage.setItem("cart", JSON.stringify(cart));
}

function productReducer(state: IProductContext, action: IProductAction): IProductContext {
    switch (action.type) {

      case 'LOADED': {
        const stored = localStorage.getItem("cart");
        return ({
            products: action.products, 
            // bundles: action.bundles,
            isLoaded: true, 
            cart: stored === null ? [] : JSON.parse(stored)
        })
      }

      case 'CART_ADD': {
        const new_cart = state.cart.includes(action.item) ? state.cart : [action.item, ...state.cart];
        saveCart(new_cart);
        return ({
            ...state, 
            cart: new_cart
        })
      }

      case 'CART_REMOVE': {
        const new_cart = state.cart.filter(item => item !== action.item);
        saveCart(new_cart);
        return ({
            ...state, 
            cart: new_cart
        })
      }

      case 'CART_CLEAR': {
        const new_cart: string[] = [];
        saveCart(new_cart);
        return ({
            ...state, 
            cart: new_cart
        })
      }

      default: {
        return state
      }
    }
}

export const ProductContextProvider: React.FC<{children: any}> = ({children}) => {
    const [authState, dispatch] = useReducer(productReducer, defaultProductState);

    useEffect(() => {
        axios.get("/api/product?all=1")
            .then((productsData: {data: {products: IProduct[]}}) => {
                // axios.get("/api/bundle")
                //     .then((bundlesData: {data: {bundles: Bundle[]}}) => {
                        
                // })
                dispatch({
                    type: "LOADED",
                    products: productsData.data.products,
                    // bundles: bundlesData.data.bundles
                })
            })
    }, [])

    return (
        <ProductDispatchContext.Provider value={dispatch}>
            <ProductContext.Provider value={authState}>
                {children}
            </ProductContext.Provider>
        </ProductDispatchContext.Provider>
    )
}