export interface FormInputProps {
    disabled?: boolean
    type?: "text" | "password"
    label: string
    value: string
    setValue: (v: string) => void
}

const FormInput: React.FC<FormInputProps> = ({value, setValue, label, disabled, type}) => {
    return (
        <div className="form-input-wrapper">
            <p className="form-input-label">{label}:</p>
            <input
                type={type || "text"}
                disabled={disabled || false}
                className="form-input"
                spellCheck={false}
                value={value}
                onChange={e => setValue(e.target.value)}
            />
        </div>
    )
}

export default FormInput;