import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import React, { ReactElement, useContext, useLayoutEffect } from "react";

import Home from "./Home";
import Catalog from "./Catalog";
import Product from "./Product";
import Dashboard from "./Dashboard";
import Policy from "./Policy";
import Login from "./Login";
import Signup from "./Signup";
import Cart from "./Cart";
import Watch from "./Watch";
import File from "./File";
import LinkPhone from "./LinkPhone";
import NotFound from "./NotFound";
import Doc1Page from "./docs/Doc1";
import Doc2Page from "./docs/Doc2";
import Doc3Page from "./docs/Doc3";
import Doc4Page from "./docs/Doc4";
import Doc5Page from "./docs/Doc5";
import PasswordResetRequestPage from "./PasswordResetRequest";
import PasswordResetSubmitPage from "./PasswordResetSubmitPage";
import BackToOld from "../components/BackToOld";
import { AuthContext } from "../context/AuthContext";
import Preloader from "../components/Preloader";
import { ProductContext } from "../context/ProductContext";
import EmailVerificationSubmit from "./EmailVerificationSubmit";
import SuccessPage from "./Success";
import FailPage from "./Fail";
import Profile from "./Profile";
import PasswordChange from "../components/PasswordChange";

const ScrollToTop: React.FC<{children: JSX.Element}> = ({children}) => {
    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children
}

export type RouteCondition = {
    condition: boolean,
    redirect: string
}

interface IProtectedRoute {
    conditions: RouteCondition[]
    children: ReactElement
}

const ProtectedRoute: React.FC<IProtectedRoute> = ({conditions, children}) => {
    const condition = conditions.find(c => c.condition);
    if(condition) return <Navigate to={condition.redirect} replace />
    return children
}

const Router: React.FC<{}> = () => {
    const {isLoaded, isLoggedIn, user} = useContext(AuthContext);
    const {isLoaded: productsLoaded } = useContext(ProductContext);
    
    if(!isLoaded || !productsLoaded) return <Preloader />

    return (
        <ScrollToTop>
            <>
                <BackToOld />
                <Routes>
                    <Route
                        path="/"
                        element={ <Home /> } 
                    />
                    <Route
                        path="/payment-success"
                        element={ <SuccessPage /> }
                    />
                    <Route
                        path="/payment-fail"
                        element={ <FailPage /> }
                    />
                    <Route
                        path="/cart"
                        element={<Cart />} 
                    />
                    <Route
                        path="/catalog"
                        element={<Catalog />} 
                    />
                    <Route
                        path="/catalog/:productId"
                        element={ <Product /> }
                    />
                    <Route
                        path="/file/:link"
                        element={ <File /> } 
                    />
                    <Route
                        path="/policy/"
                        element={ <Policy /> }
                    />
                    <Route
                        path="/policy/doc1"
                        element={ <Doc1Page /> } 
                    />
                    <Route
                        path="/policy/doc2"
                        element={ <Doc2Page /> } 
                    />
                    <Route
                        path="/policy/doc3"
                        element={ <Doc3Page /> } 
                    />
                    <Route
                        path="/policy/doc4"
                        element={ <Doc4Page /> } 
                    />
                    <Route
                        path="/policy/doc5"
                        element={ <Doc5Page /> } 
                    />
                    <Route
                        path="/login" 
                        element={
                            <ProtectedRoute
                                conditions={[
                                    {
                                        condition: isLoggedIn,
                                        redirect: "/dashboard"
                                    },
                                ]}
                                children={<Login />}
                            />
                    } />
                    <Route
                        path="/signup"
                        element={
                            <ProtectedRoute
                                conditions={[
                                    {
                                        condition: isLoggedIn,
                                        redirect: "/dashboard"
                                    },
                                ]}
                                children={<Signup />}
                            />
                    } />
                    <Route
                        path="/phone-verification" 
                        element={
                            <ProtectedRoute
                                conditions={[
                                    {
                                        condition: !isLoggedIn,
                                        redirect: "/login"
                                    },
                                    {
                                        condition: user?.phone_verified ?? false,
                                        redirect: "/dashboard"
                                    },
                                ]}
                                children={<LinkPhone />}
                            />
                    } />
                    <Route
                        path="/reset-password" 
                        element={
                            <ProtectedRoute
                                conditions={[
                                    {
                                        condition: isLoggedIn,
                                        redirect: "/dashboard"
                                    },
                                ]}
                                children={<PasswordResetRequestPage />}
                            />
                    } />
                    <Route
                        path="/reset-password/:token" 
                        element={
                            <ProtectedRoute
                                conditions={[
                                    {
                                        condition: isLoggedIn,
                                        redirect: "/dashboard"
                                    },
                                ]}
                                children={<PasswordResetSubmitPage />}
                            />
                    } />
                    <Route
                        path="/verify-email/:token" 
                        element={
                            <ProtectedRoute
                                conditions={[]}
                                children={<EmailVerificationSubmit />}
                            />
                    } />
                    <Route
                        path="/dashboard" 
                        element={
                            <ProtectedRoute
                                conditions={[
                                    {
                                        condition: !isLoggedIn,
                                        redirect: "/login"
                                    },
                                ]}
                                children={<Dashboard />}
                            />
                    } />
                    <Route
                        path="/profile" 
                        element={
                            <ProtectedRoute
                                conditions={[
                                    {
                                        condition: !isLoggedIn,
                                        redirect: "/login"
                                    },
                                ]}
                                children={<Profile />}
                            />
                    } />
                    <Route
                        path="/dashboard/:productId" 
                        element={
                            <ProtectedRoute
                                conditions={[
                                    {
                                        condition: !isLoggedIn,
                                        redirect: "/login"
                                    },
                                    {
                                        condition: !user?.phone_verified || false,
                                        redirect: "/phone-verification"
                                    }
                                ]}
                                children={<Watch />}
                            />
                    } />
                    <Route
                        path="*" 
                        element={ <NotFound /> }
                    />
                </Routes>
            </>
        </ScrollToTop>
    )
}

export default Router;