import { useContext } from "react";
import { ProductContext } from "../context/ProductContext";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ym from "react-yandex-metrika";


const ProductLocked: React.FC<{productId: string}> = ({productId}) => {
    const { products } = useContext(ProductContext);
    const { t, i18n } = useTranslation();

    const targetProduct = products.find(p => p._id === productId);

    return (
        <div className="product-locked">
            <img
                src="/icon/lock-keyhole.svg"
                className="icon"
                alt="locked"
            />
            <span className="nav-link">
                {t("product_locked")}
                <Link className="link" to={`/catalog/${targetProduct?._id}`}  onClick={() => ym('hit', `product-locked-${productId}`)}>
                    {
                        targetProduct?.[i18n.language === "ru" ? "title_ru" : "title_en"]
                    }
                </Link>
            </span>
        </div>
    )
}

export default ProductLocked;