import axios from "axios";
import {useState} from "react";
import { Link } from "react-router-dom"

import Header from "../components/Header";
import Social from "../components/Social";
import { useTranslation } from "react-i18next";

const emailRegexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

const PasswordResetRequestPage: React.FC<{}> = () => {
    const { t } = useTranslation();
    const [error, setError] = useState<string | null>(null);
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");

    function RequestPasswordReset(){
        setError(null);
        setLoading(true);
        axios.post("/api/auth/request-reset-password", {
            email
        }).then(({data}: any) => {
            setSuccess(true);
        }).catch((error: any) => {
            setError(t("form_error_login_invalid"))
        }).finally(() => {
            setLoading(false);
        })
    }
    return (
        <div className="page-wrapper">
            <Header />
            <div className="light-bg full-page-block">
                <div className="auth-form login-form form">
                    <div className="form-title">{t("password_reset_form_title")}</div>
                    <p className={`tip ${success ? "success" : ""}`}>{t(success ? "password-reset-requested-tip" : "password-reset-tip")}</p>
                    {
                        error && <div className="auth-error">{error}</div>
                    }
                    <p className="auth-label">{t("form_label_email")}:</p>
                    <input
                        disabled={success || loading}
                        className="auth-input"
                        type="text" value={email}
                        onChange={e => setEmail(e.target.value)}
                    />
                    <button
                        disabled={success || loading || !emailRegexp.test(email)}
                        className={`auth-submit ${loading ? "loading" : ""}`}
                        onClick={RequestPasswordReset}
                        children={t("reset_password")}
                    />
                    <div className="auth-links">
                        <Link className="auth-link" to="/login">{t("nav_login")}</Link>
                        <Link className="auth-link" to="/signup">{t("nav_signup")}</Link>
                    </div>
                </div>
            </div>
            <Social />
        </div>
    )
}

export default PasswordResetRequestPage;