import axios from "axios";
import { useEffect, useRef, useState } from "react"
import ReactPlayer from "react-player"


const Player: React.FC<{video: string, phone: string}> = ({video, phone}) => {
    const [playing, setPlaying]    = useState(false);
    const [played, setPlayed]      = useState(0);
    const [seconds, setSeconds]    = useState(0);
    const [loaded, setLoaded]      = useState(0);
    const [ready, setReady]        = useState(true)
    const [launched, setLaunched]  = useState(false)
    const [duration, setDuration]  = useState(0);
    const [seeking, setSeeking]    = useState(false);

    const PlayerRef = useRef<any>(null);

    const [videoSource, setVideoSource] = useState<string>();

    useEffect(() => {
        axios({
            method: "get",
            url: video
        }).then(({data}) => {
            console.log(data)
            setVideoSource(data)
        })
    }, [video]);

    const formatDate = (time: number) => {
        const s = Math.floor(time);
        const minutes = Math.floor(s / 60);
        const seconds = s % 60
        return <p className="TimeStamp"><span>{minutes}</span>:<span>{String(seconds).padStart(2, "0")}</span></p>
    }
    return (
        <div className="VideoPlayerWrapper">
            <div className={"VideoPlayer " + (launched ? "launched" : "")} onContextMenu={(e) => e.preventDefault()}>
                {!videoSource ? 
                    <div className="novideo">
                        <i className="fal fa-video-slash" />
                        <p>К сожалению, в настоящий момент видео недоступно</p>
                    </div> :
                <>
                <ReactPlayer
                    ref={PlayerRef}
                    width="100%"
                    height="100%"
                    url={videoSource}
                    playsinline={true}
                    controls={false}
                    playing={playing && !seeking}
                    onReady={() => setReady(true)}
                    onClick={() => setPlaying(s => !s)}
                    onPlay={ () => setPlaying(true)}
                    onPause={() => setPlaying(false)}
                    onEnded={() => {
                        setSeconds(0)
                        setPlaying(false)
                    }}
                    onError={e => console.log('onError', e)}
                    onProgress={progress => {
                        setSeconds(progress.playedSeconds)
                        setPlayed(progress.played)
                        setLoaded(progress.loaded)
                    }}
                    onDuration={duration => setDuration(duration)}
                />
                <div className="Watermark">
                    <p>{phone}</p>
                    <p>Копирование запрещено</p>
                </div>
                <div className="Controls">
                    <div
                        onClick={() => setPlaying(s => !s)}
                        className={"PlayPause " + (playing ? "playing" : "paused")}
                    >
                        <img src="/icon/play.svg" alt="" className="player-btn play" />
                        <img src="/icon/pause.svg" alt="" className="player-btn pause" />
                    </div>
                    <div className="Progress">
                        <div className="ProgressBar" style={{width: 100 * played + "%"}} />
                        <div className="LoadBar" style={{width: 100 * loaded + "%"}} />
                        <input
                            className="RangeSlider"
                            type='range' min={0} max={1} step='any'
                            value={played}
                            onTouchStart={() => setSeeking(true)}
                            onMouseDown={() => setSeeking(true)}
                            onChange={e => {
                                const played = parseFloat(e.target.value)
                                setPlayed(played)
                                setSeconds(played * duration)
                            }}
                            onTouchEnd={(e: any) => {
                                setSeeking(false)
                                PlayerRef.current.seekTo(parseFloat(e.target.value))
                            }}
                            onMouseUp={(e: any) => {
                                setSeeking(false)
                                PlayerRef.current.seekTo(parseFloat(e.target.value))
                            }}
                        />
                        <div className={"Notch " + (seeking ? "seeking" : "")} style={{left: "calc(" + 100 * played + "% - 6px)"}} />
                    </div>
                    <div className="Duration">
                        {formatDate(seconds)}
                        <span className="Delimeter">/</span>
                        {formatDate(duration)}
                    </div>
                </div>
                </>
                }
            </div>
        </div>
    )
}

export default Player