import axios from "axios";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

const LANG_TAG = "lang";

export const LangController: React.FC = () => {
    const [query, setQuery] = useSearchParams();
    const { i18n } = useTranslation();
    
    useEffect(() => {
        const lang = query.get(LANG_TAG);
        if(lang){
            i18n.changeLanguage(
                lang.toLowerCase() === "ru"
                    ? "ru"
                    : "en"
            )
        }
    }, [query, setQuery]);

    return null;
}